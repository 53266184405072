import React, { useState, useEffect } from 'react';

interface DateTimeComponentProps {
  is24HourFormat: boolean;
  timeZone: any | null
}

const DateTimeComponent: React.FC<DateTimeComponentProps> = ({ is24HourFormat, timeZone }) => {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = (date: Date) => {
    const userTimeZone = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: !is24HourFormat,
      timeZone: userTimeZone
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  return (
    <div className='flex items-center gap-3'>
      <div className='text-[26px]'>{formatTime(dateTime)}</div>
    </div>
  );
};

export default DateTimeComponent;