import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import SelectTask from '../adminComponents/SelectTaskAdmin';
import List from '@mui/material/List';
import { t } from 'i18next';
import Button from '@mui/material/Button';

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: {
    id: number,
    title: string
  },
  company: {
    id: number,
    title: string
  },
  supervisorId: number;
}

interface AddModalProps {
  selectCategoryName: string,
  setOpenAddCategory: any,
  selectedCatogoryId: any,
  updateTree: any;
}


const AddCategory: React.FC<AddModalProps> = ({ selectCategoryName, setOpenAddCategory, selectedCatogoryId, updateTree }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [newCategory, setNewCategory] = useState('');
  const handleClose = () => setOpenAddCategory(false);
  const [selectedTask, setSelectedTask] = useState<any[]>([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState<any | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [isPasteDisabled, setIsPasteDisabled] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        const { data: usersData } = await axios.get('users');
        setUsers(usersData);
        setSelectedSupervisor(null);
      } catch (e) {
      }
    })();
  }, []);

  useEffect(() => {
    const copiedTasks = localStorage.getItem('copiedTasks');
    setIsPasteDisabled(!copiedTasks);
  }, []);

  const handleAddCategory = async () => {
    try {
      if (!newCategory || !newCategory.trim()) {
        enqueueSnackbar('Add name category', { variant: 'error' });
        return
      }
      await axios.post('category/add', {
        name: newCategory,
        description: '1',
        parentId: selectedCatogoryId !== -1 ? Number(selectedCatogoryId) : null,
        taskId: selectedTask.map((task: any) => task.value),
        supervisorId: selectedSupervisor
      });
      setNewCategory('')
      updateTree();
      handleClose();
      setSelectedSupervisor(null);
      enqueueSnackbar(`Add child category for ${selectCategoryName}`, { variant: 'success' });
    } catch (error: any) {
      console.error('Error', error);
      enqueueSnackbar('Error', { variant: 'error' });
    }
  };

  const handleCopyTasks = () => {
    localStorage.setItem('copiedTasks', JSON.stringify(selectedTask));
    setIsPasteDisabled(false);
    enqueueSnackbar('Tasks copied successfully', { variant: 'success' });
  };

  const handlePasteTasks = () => {
    const copiedTasks = localStorage.getItem('copiedTasks');
    if (copiedTasks) {
      setSelectedTask(JSON.parse(copiedTasks));
      enqueueSnackbar('Tasks pasted successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('No tasks to paste', { variant: 'error' });
    }
  };

  return (
    <Box sx={{
      width: 480,
      height: '200%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div
          className='my-[5px] mx-[25px] text-[var(--text-color)]'>
          <h1 className='text-[20px] text-right font-bold mb-[30px]'>{t('AddCategory')}</h1>
          <div>
            {selectedCatogoryId !== -1 ? (
              <div>
                {t('AddChildCategoryFor')} <span className=' font-semibold'>'{selectCategoryName}'</span>
              </div>
            ) : (
              <div>{t('AddParentCategory')}</div>
            )}
            <div>
              <input
                className='w-full text-[18px] p-[4px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                type="text"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)} />
            </div>
            <div className='flex flex-col mb-4'>
              <div>{t('SelectSupervisor')}:</div>
              <select
                className="edit-select p-[5px] rounded-[5px] edit-input"
                onChange={(e) => { setSelectedSupervisor(parseInt(e.target.value) || null) }}>
                <option key='none' value="null">none</option>
                {users
                  ?.sort((a, b) => {
                    if (a.first_name < b.first_name) return -1;
                    if (a.first_name > b.first_name) return 1;
                    return 0;
                  })
                  .map((user) => (
                    <option key={user.id} value={user.id} >
                      {user.first_name} {user.last_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className='my-[10px]'>
              <div className='text-[18px]'>{t('AddTasks')}</div>
              <SelectTask
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask} />
            </div>
            <div className='flex justify-end items-center gap-[10px] mb-[25px]'>
              <Button
                variant="outlined"
                style={{
                  fontSize: 12,
                  padding: '2px 7px'
                }}
                onClick={handleCopyTasks}>
                {t('Copy Tasks')}</Button>
              <Button
                variant="outlined"
                style={{
                  fontSize: 12,
                  padding: '2px 7px'
                }}
                onClick={handlePasteTasks}
                className={isPasteDisabled ? 'disabled:opacity-50' : ''}
                disabled={isPasteDisabled}
              >
                {t('Paste Tasks')}</Button>
            </div>
            <div
              className='flex justify-between items-center gap-4'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleAddCategory()}>
                {t('Add')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleClose()}>
                {t('Cancel')}
              </Button>
            </div>
          </div>
        </div>
      </List>
    </Box >
  );
}

export default AddCategory;