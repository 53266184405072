// import logoLight from '../../img/logo-light.png';
// import logoDark from '../../img/logo-dark.png';
// import logoB from '../../img/logo-b.png'
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { setAuth } from '../../redux/authSlice';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import '../i18n/i18n';
import { useTranslation } from 'react-i18next';
import {
  faLayerGroup,
  faCircleUp,
  faTable,
  faGear,
  faUserTie,
  faToolbox,
  faWindowRestore,
  faCoins,
  faDownload,
  faUserGroup,
  faHashtag,
  faCalendarCheck
} from '@fortawesome/free-solid-svg-icons';

interface NavProps {
  isDarkTheme: boolean,
  access: any,
  urlLogo: any
}

const Nav: React.FC<NavProps> = ({ isDarkTheme, access, urlLogo }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const [companyName, setCompanyName] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          const { data: companyNameData } = await axios.get(`/company/${data.companyId}`);
          setCompanyName(companyNameData.companyName)
        }
        dispatch(setAuth(true));
      } catch (error) {
        console.error('Error fetching user data:', error);
        dispatch(setAuth(false));
      }
    };
    if (auth) {
      fetchData();
    }
  }, [auth, dispatch]);

  const isMenuActive = (path: string) => location.pathname === path;

  let li;

  if (auth && access) {
    li =
      <ul className='flex gap-[5px] flex-col decoration-none normal-case list-none px-[15px] py-[10px] mt-2'>
        {access.timesheets_get > 0 &&
          (<Link to="/" className={isMenuActive('/timesheets') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('Timesheets')}
              <Tooltip title="Timesheets" placement="right-start">
                <FontAwesomeIcon icon={faLayerGroup} className='opacity-70 text-[20px] text-[var(--text-color)]' />
              </Tooltip></li></Link>
          )}
        {access.approve_get > 0 &&
          (<Link to="/approve" className={isMenuActive('/approve') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('Approve')}
              <Tooltip title="Approve" placement="right-start">
                <FontAwesomeIcon icon={faTable} className='opacity-70 text-[20px] text-[var(--text-color)]' />
              </Tooltip></li></Link>
          )}
        {access.reports_get > 0 &&
          (<Link to="/reports" className={isMenuActive('/reports') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('Reports')}
              <Tooltip title="Reports" placement="right-start">
                <FontAwesomeIcon icon={faCircleUp} className='opacity-70 text-[20px] text-[var(--text-color)]' />
              </Tooltip></li></Link>
          )}
        {access.clients_get > 0 &&
          (<Link to="/clients" className={isMenuActive('/clients') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('Clients')}
              <Tooltip title="Clients" placement="right-start">
                <FontAwesomeIcon icon={faUserTie} className='opacity-70 text-[20px] text-[var(--text-color)]' />
              </Tooltip></li></Link>
          )}
        {access.equipment_get > 0 &&
          (<Link to="/equipment" className={isMenuActive('/equipment') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('Equipment')}
              <Tooltip title="Equipment" placement="right-start">
                <FontAwesomeIcon icon={faToolbox} className='opacity-70 text-[20px] text-[var(--text-color)]' />
              </Tooltip></li></Link>
          )}
        {access.booking_get > 0 &&
          (<Link to="/booking" className={isMenuActive('/booking') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('Booking')}
              <Tooltip title="Booking" placement="right-start">
                <FontAwesomeIcon icon={faWindowRestore} className='opacity-70 text-[20px] text-[var(--text-color)]' />
              </Tooltip></li></Link>
          )}
        {access.expenses_get > 0 &&
          (<Link to="/expenses" className={isMenuActive('/expenses') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('Expenses')}
              <Tooltip title="Expenses" placement="right-start">
                <FontAwesomeIcon icon={faCoins} className='opacity-70 text-[20px] text-[var(--text-color)]' />
              </Tooltip></li></Link>
          )}
        {access.orders_get > 0 &&
          (<Link to="/orders" className={isMenuActive('/orders') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('Orders')}
              <Tooltip title="Orders" placement="right-start">
                <FontAwesomeIcon icon={faDownload} className='opacity-70 text-[20px] text-[var(--text-color)]' />
              </Tooltip></li></Link>
          )}
        <hr className='hr mt-4 mb-1' />
        {access.settings > 0 ? (
          <Link to="/settings" className={isMenuActive('/settings') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('Settings')}
              <Tooltip title="Settings" placement="right-start">
                <FontAwesomeIcon icon={faGear} className='opacity-70 text-[20px] text-[var(--text-color)]' />
              </Tooltip></li></Link>)
          : ''}
        {access.users_get > 0 ?
          <Link to="/users" className={isMenuActive('/users') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('Users')}
              <Tooltip title="Users" placement="right-start">
                <FontAwesomeIcon icon={faUserGroup} className='opacity-70 text-[18px] text-[var(--text-color)]' />
              </Tooltip></li></Link>
          : ''}
        {access.toil_get > 0 ?
          <Link to="/toil" className={isMenuActive('/toil') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('Toil')}
              <Tooltip title="TOIL" placement="right-start">
                <FontAwesomeIcon icon={faCalendarCheck} className='opacity-70 text-[20px] mr-[2px] text-[var(--text-color)]' />
              </Tooltip></li>
          </Link> : ''}
        {access.description === -1 ? (
          <Link to="/users-reports" className={isMenuActive('/users-reports') ? 'font-semibold rounded-[5px] bg-[var(--btn-user)]' : ''}>
            <li className='flex justify-between items-center cursor-pointer p-[var(--nav-pading)] nav-li hover:rounded hover:font-semibold hover:bg-[var(--btn-user)]'>
              {t('UsersReports')}
              <Tooltip title="Users Reports" placement="right-start">
                <FontAwesomeIcon icon={faHashtag} className='opacity-70 text-[20px] mr-[2px] text-[var(--text-color)]' />
              </Tooltip></li>
          </Link>) : ''}
      </ul>
  } else {
    li = <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
      <CircularProgress />
    </Box>;
  }

  return (
    <>
      {auth && (
        <div className='nav-box left-0 top-0 z-50 overflow-hidden overflow-y-auto bg-[var(--bg-form)] w-[var(--view-nav)]'>
          <div className='flex flex-col item-center h-full w-full'>
            <div className=' mt-1 px-4 w-full flex items-center justify-center'>
              <Link to="/" className='logo-b mt-[10px] text-[12px] text-center'>
                {urlLogo ?
                  <img
                    className="w-full h-[40px] object-cover object-left"
                    src={urlLogo} alt="logo" /> : <div>{companyName}</div>}
                {/* <img src={logoB} alt="logo-b" /> */}
              </Link>
              <Link to="/" className='logo-a mt-[10px] text-[24px]'>
                {urlLogo ?
                  <img
                    className="w-[200px] h-[40px] object-content"
                    src={urlLogo} alt="logo" /> : <div>{companyName}</div>}
                {/* <img src={isDarkTheme ? logoDark : logoLight} alt="logo" /> */}
              </Link></div>
            <div className="w-full ul-no-scroll">
              {li}
            </div>
          </div>
        </div >
      )}
    </>
  )
}

export default Nav