import { useEffect, useState, useCallback } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Breadcrums from './util/Breadcrums';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment';
import 'moment-timezone';
import enGB from 'date-fns/locale/en-GB';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import TreeViewComponent from './util/TreeViewComponent';
import SelectTask from './timesheetsComp/SelectTask';
import { transformToHierarchy } from './util/tree';
import TemplateTable from './templateComponents/TemplateTable';
import { useSnackbar } from 'notistack';
import AddTemplate from './modal/AddTemplate';
import TemplateView from './templateComponents/TemplateView';
import Button from '@mui/material/Button';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import QueueIcon from '@mui/icons-material/Queue';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

registerLocale('en-GB', enGB);

interface TemplateProps {
  access: any,
  is24HourFormat: any
}

interface Temp {
  temp_group_number: string;
  temp_name: string;
}

const Template: React.FC<TemplateProps> = ({ access, is24HourFormat }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const [locationName, setLocationName] = useState('');
  const [timeFrom, setTimeFrom] = useState(moment().hour(9).minute(0).toDate());
  const [timeTo, setTimeTo] = useState(moment().hour(9).minute(0).toDate());
  const [isCategoryListVisible, setCategoryListVisible] = useState(false);
  const [description, setDescription] = useState('')
  const [selectedCategoryId, setSelectedCategoryId] = useState<number[]>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [categoryName, setCategoryName] = useState<any[]>([]);
  const [allCategory, setAllCategory] = useState<any[]>([]);
  const [allTemplate, setAllTemplate] = useState<any[]>([]);
  const [test, setTest] = useState<any>();
  const [openCheckModal, setCheckOpenModal] = useState(false);
  const [reports, setReports] = useState<any[]>([]);
  const [tasks, setTasks] = useState();
  const [selectedTasks, setSelectedTasks] = useState<any[]>([]);
  const [selectedDay, setSelectedDay] = useState('1');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedTemplateName, setSelectedTemplateName] = useState('');
  const [allTemplateSelect, setAllTemplateSelect] = useState<Temp[]>([]);
  const transformToHierarchyCallback = useCallback(transformToHierarchy, []);
  const [openAdd, setOpenAdd] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const dispatch = useDispatch();

  const handleAddName = (e: any) => {
    const selectedValue = e.target.value;
    setSelectedTemplate(selectedValue);
    const selectedTemp = allTemplateSelect.find((temp: any) => temp.temp_group_number === selectedValue);
    if (selectedTemp) {
      setSelectedTemplateName(selectedTemp.temp_name);
    }
  }

  const handleAddorCreate = (item: string) => {
    setOpenAdd(true);
    setSelectedTemplate(item)
  }

  // change format date to string 
  const formatTime = (date: Date) => {
    return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  };

  //show category
  const handleClickCategoryBox = (event: React.MouseEvent) => {
    setCategoryListVisible(!isCategoryListVisible);
    event.stopPropagation();
  };

  const handleCategoryListClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  //add date
  const handleDateChange = (e: any) => {
    const newSelectedDay = e.target.value;
    setSelectedDay(newSelectedDay);
  };

  //add time from
  const handleTimeFromChange = (time: Date) => {
    setTimeFrom(time);
  };

  //add time to
  const handleTimeToChange = (time: Date) => {
    setTimeTo(time);
  };

  //add value to description
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDescription(value);
  };

  //category select
  const handleNodeSelect = (nodeId: number) => {
    setSelectedCategoryId([nodeId])
    if (nodeId === -1) {
      setCategoryName(['none'])
      setCategoryListVisible(false);
    }
    const selectedCategory = allCategory?.find(node => node.id === Number(nodeId));
    if (selectedCategory) {
      const hasChildren = allCategory.some(node => node.parentId === Number(nodeId));
      if (hasChildren) {
        return true;
      }
      setCategoryName(selectedCategory.name)
      setTest(nodeId)
      handleTimeFromChange(timeFrom)
      setCategoryListVisible(false);
    } else {
      console.log('Category not found');
    }
  };

  // add object 
  const handleAddReport = () => {
    if ((moment(timeFrom).format('HH:mm:ss')) >= (moment(timeTo).format('HH:mm:ss'))) {
      enqueueSnackbar('Invalid time range: TimeFrom must be less than TimeTo', { variant: 'error' });
      return;
    }
    if (selectedCategoryId.length === 0) {
      enqueueSnackbar('Invalid category: You did not select a category', { variant: 'error' });
      return;
    }
    if (categoryName.length === 0) {
      enqueueSnackbar('Invalid category: You did not select a category', { variant: 'error' });
      return;
    }
    if (selectedTasks.length === 0) {
      enqueueSnackbar('Invalid task: You did not select a task', { variant: 'error' });
      return;
    }
    const newReport = {
      selectedDay,
      startTime: formatTime(timeFrom),
      endTime: formatTime(timeTo),
      categoryId: selectedCategoryId[0],
      tasksId: selectedTasks,
      description
    };
    setReports((prevReports) => [...prevReports, newReport]);
    setDescription('');
  };

  useEffect(() => {
    const handleDocumentClick = () => {
      setCategoryListVisible(false);
    };
    if (isCategoryListVisible) {
      document.addEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isCategoryListVisible]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          setLocationName(location.pathname);
          dispatch(setAuth(true));
        }
        const { data: categoryData } = await axios.get('category');
        const { data: templateData } = await axios.get('template/user');
        const { data: taskData } = await axios.get('task');
        const { data: template } = await axios.get('template/user/group');
        setAllTemplateSelect(template);
        setTasks(taskData);
        setAllTemplate(templateData);
        setAllCategory(categoryData);
        const hierarchyData = transformToHierarchyCallback(categoryData);
        setCategory(hierarchyData);
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch, location.pathname, reports]);

  const refresh = async () => {
    try {
      const { data } = await axios.get('template/user');
      setAllTemplate(data);
    } catch (error: any) {
      console.log('error');
    }
  }

  return (
    <>
      {/* <Breadcrums locationName={locationName} /> */}
      {auth && access.timesheets_get > 0 ? (
        <div>
          <div className="bg-[var(--bg-form)] w-full min-w-[500px] flex justify-left items-center gap-[5px] p-[15px] mb-[15px] rounded-[5px] flex-wrap">
            <div className='flex gap-[5px]'>
              <div className="flex max-w-[140px]">
                <select
                  onChange={(e) => handleDateChange(e)}
                  className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'>
                  <option value="1">{t('Monday')}</option>
                  <option value="2">{t('Tuesday')}</option>
                  <option value="3">{t('Wednesday')}</option>
                  <option value="4">{t('Thursday')}</option>
                  <option value="5">{t('Friday')}</option>
                  <option value="6">{t('Saturday')}</option>
                  <option value="7">{t('Sunday')}</option>
                </select>
              </div>
              <div className='flex max-w-[120px]'>
                <DatePicker
                  selected={timeFrom}
                  onChange={(time: any) => handleTimeFromChange(time)}
                  className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                  placeholderText="Select Time"
                  showTimeSelect
                  dateFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                  timeFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                  showTimeSelectOnly
                  timeIntervals={15}
                />
              </div>
              <div className='flex max-w-[120px]'>
                <DatePicker
                  selected={timeTo}
                  onChange={(time: any) => handleTimeToChange(time)}
                  className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                  showTimeSelect
                  dateFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                  timeFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                  showTimeSelectOnly
                  timeIntervals={15}
                />
              </div>
            </div>
            <div className="timesheets-category-box bg-[var(--bg-input)] text-[var(--text-color)] relative w-[180px] flex-grow text-[16px] rounded-[5px] py-[5px] px-[10px] flex justify-between items-center cursor-text"
              onClick={handleClickCategoryBox}>
              <div className="width-fix">{categoryName.length ? categoryName : <div className='text-[#80807a] text-[16px]'>{t('SelectCategory')}</div>}</div>
              <div className="timesheets-category-arrow"><FontAwesomeIcon icon={faChevronDown} className='text-[13px] cursor-pointer opacity-50 ' /></div>
              {isCategoryListVisible && (
                <div className="timesheets-category-list fix-height w-full absolute top-[45px] right-0 z-[999] py-[5px]"
                  onClick={handleCategoryListClick}>
                  <TreeViewComponent
                    nodes={category}
                    onNodeSelect={handleNodeSelect}
                    updateTree={''}
                    openCheckModal={openCheckModal}
                    selectedCategoryId={''} />
                </div>
              )}
            </div>
            <div
              className='w-[300px] relative flex-grow'>
              <SelectTask
                test={test}
                setSelectedTasks={setSelectedTasks}
                selectedTasks={selectedTasks} />
            </div>
            <div className='flex gap-[10px] items-center flex-grow w-[350px]'>
              <div className='w-full'>
                <input
                  type="text"
                  className='w-full input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] p-[5px] rounded-[5px]'
                  value={description}
                  placeholder={t('DescriptionPoins')}
                  onChange={handleDescriptionChange} />
              </div>
              <Button
                variant="contained"
                endIcon={<PlaylistAddIcon
                  sx={{ color: 'white !important' }} />}
                sx={{
                  padding: '5px 20px',
                  fontSize: 14,
                  color: 'white !important'
                }}
                onClick={handleAddReport}>
                {t('Add')}
              </Button>
            </div>
          </div>
          <div className='flex gap-[15px] media-flex-wrap w-full items-start'>
            <div className='relative box-users flex flex-col justify-start p-[15px] pt-[20px] rounded-[5px] w-[50%] flex-grow'>
              {reports && reports.length > 0 ? (
                <>
                  <div
                    className='flex justify-between mb-[10px]'>
                    <Button
                      variant="contained"
                      startIcon={<CreateNewFolderIcon
                        sx={{ color: 'white !important' }} />}
                      sx={{
                        padding: '5px 20px',
                        fontSize: 12,
                        color: 'white !important'
                      }}
                      onClick={() => handleAddorCreate('')}>
                      {t('CreateNew')}
                    </Button>
                    <div
                      className='flex gap-[10px] items-center'>
                      <FormControl variant="standard"
                        sx={{
                          position: 'absolute',
                          top: '4px',
                          right: '115px'
                        }}>
                        <InputLabel
                          sx={{
                            paddingLeft: '10px',
                            paddingTop: '4px',
                            fontSize: 12,
                            color: 'var(--text-color)',
                            display: 'flex',
                            opacity: '.7'
                          }}>Select Template</InputLabel>
                        <Select
                          value={selectedTemplate || ''}
                          label="Select template"
                          onChange={(e) => handleAddName(e)}
                          sx={{
                            padding: '0 10px',
                            borderRadius: '5px',
                            minWidth: '150px',
                            border: 'var(--border-input)',
                            fontSize: 14,
                            color: 'var(--text-color)',
                            display: 'flex'
                          }}>
                          <MenuItem value="">
                            none
                          </MenuItem>
                          {allTemplateSelect.map((temp: any, index: number) => (
                            <MenuItem
                              key={index}
                              value={temp.temp_group_number}>{temp.temp_name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button
                        variant="contained"
                        endIcon={<QueueIcon
                          sx={{ color: 'white !important' }} />}
                        sx={{
                          padding: '5px 0',
                          width: '90px',
                          fontSize: 12,
                          color: 'white !important'
                        }}
                        disabled={selectedTemplate === ''}
                        onClick={() => handleAddorCreate(selectedTemplate)}
                      >
                        {t('Add')}
                      </Button>
                    </div>
                  </div>
                  <TemplateTable
                    rows={reports}
                    allCategory={allCategory}
                    setReports={setReports}
                    setSelectedTemplate={setSelectedTemplate}>
                  </TemplateTable>
                </>) : (
                <div
                  className='flex items-center justify-center'>
                  <div
                    className='opacity-45'>{t('Empty')}</div>
                </div>
              )}

            </div>
            <div className='relative box-users flex flex-col justify-start p-[15px] rounded-[5px] pb-[20px] w-[50%] flex-grow'>
              {allTemplate && allTemplate.length > 0 ? (
                <>
                  <TemplateView
                    rows={allTemplate}
                    allCategory={allCategory}
                    tasks={tasks}
                    is24HourFormat={is24HourFormat}
                    refresh={refresh}>
                  </TemplateView>
                </>) : (
                <div
                  className='flex items-center justify-center'>
                  <div
                    className='opacity-45'>{t('Empty')}</div>
                </div>
              )}

            </div>
          </div>
          {openAdd &&
            <AddTemplate
              openAdd={openAdd}
              setOpenAdd={setOpenAdd}
              setCheckOpenModal={setCheckOpenModal}
              setReports={setReports}
              reports={reports}
              selectedTemplate={selectedTemplate}
              selectedTemplateName={selectedTemplateName}
              setSelectedTemplate={setSelectedTemplate}>
            </AddTemplate>}
        </div >
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}

export default Template
