import axios from "axios";
import { SyntheticEvent, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

export const Reset = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState('');
  const { token } = useParams();

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!password || !passwordConfirm) {
      setError('Please fill in both password fields.');
      return;
    }
    if (password !== passwordConfirm) {
      setError('Passwords do not match.');
      return;
    }
    try {
      await axios.post('reset', {
        token,
        password,
        password_confirm: passwordConfirm
      });
      setRedirect(true)
    } catch (error) {
      setError('An error occurred while resetting the password.');
    }
  }

  if (redirect) {
    return <Navigate to='/login' />
  }

  let link;

  if (error !== '') {
    link = <div className='relative px-5 py-3 mb-4 text-center rounded text-red-900 bg-red-200 border-gray-200'>{error}</div>
  }

  return (
    <div className="max-w-[480px] m-auto flex flex-col justify-start p-[40px] rounded-[5px] form-box">
      {link}
      <h1 className='mt-[20px] text-[26px]'>Please put your email</h1>
      <form className="flex flex-col justify-evenly flex-grow pb-4" onSubmit={submit}>
        <input placeholder="Password" type="password" id="password" onChange={e => setPassword(e.target.value)} />
        <input placeholder="Confirm Password" type="password" id="confirm_pwd" onChange={e => setPasswordConfirm(e.target.value)} />
        <button className="bg-[#1976d2] text-white cursor-pointer rounded-[5px] button-border p-[10px] mx-auto mt-[20px] w-[70%] hover:bg-[#176bbe]" type='submit'>Submit</button>
      </form>
    </div>
  )
}