import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { setAuth } from '../../redux/authSlice';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faBarsStaggered, faBell } from '@fortawesome/free-solid-svg-icons';
import Badge from '@mui/material/Badge';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MaterialUISwitch } from '../util/MaterialUISwitch';
import DateTimeComponent from '../navComponents/DateTimeComponent';
import Drawer from '@mui/material/Drawer';
import TemporaryDrawer from '../headerComponents/TemporaryDrawer';
import Profile from '../modal/Profile';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SelectLanguage from '../headerComponents/SelectLanguage';
import { t } from 'i18next';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import Person2Icon from '@mui/icons-material/Person2';

interface HeaderProps {
  isDarkTheme: boolean,
  setIsDarkTheme: any,
  userInfo: string;
  refreshUserHeder: any;
  userRole: any;
  lng: any;
  setLng: any;
  userNotice: any;
  urlImage: any;
  baseUrl: string;
  is24HourFormat: boolean;
  timeZone: any
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
  second_name: string;
  email: string;
  date_of_birth: Date;
  contract_start_date: Date;
  sex: string;
  mobile_phone: string;
  work_phone: string;
  postcode: string;
  city: string;
  address: string;
  job_title: string;
  hoursPerWeek: number;
  min_leave_entitlement: number;
  role: {
    id: number,
    title: string,
    color: string
  },
  company: {
    id: number,
    title: string
  },
  supervisorId: number;
}

interface UserInfo {
  first_name: string;
  last_name: string;
  roleId: number;
}

const Header: React.FC<HeaderProps> = ({
  isDarkTheme,
  setIsDarkTheme,
  userRole,
  userInfo,
  refreshUserHeder,
  lng,
  setLng,
  userNotice,
  urlImage,
  baseUrl,
  is24HourFormat,
  timeZone }) => {
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [small, setSmall] = useState(false);
  const [allInfo, setAllInfo] = useState<UserInfo>({ first_name: '', last_name: '', roleId: 0 });
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [open, setOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));

  const SearchIconWrapper = styled('div')(({ theme }) => ({
    height: '10px',
    zIndex: '2',
    width: '10px',
    left: '-12px',
    position: 'absolute'
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    position: 'relative',
    width: '100%',
    '& .MuiInputBase-input': {
      padding: '3px',
      paddingLeft: `calc(1em + 10px)`,
      background: 'var(--bg-input)',
      border: 'var(--border-input)',
      borderRadius: '5px',
      transition: theme.transitions.create('width'),
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get('user');
        dispatch(setAuth(true));
        setAllInfo(data)
        setSelectedUser(data)
      } catch (error) {
        console.error('Error fetching user data:', error);
        dispatch(setAuth(false));
      }
    };

    if (auth) {
      fetchData();
    }
  }, [auth, dispatch]);

  useEffect(() => {
    localStorage.setItem('darkTheme', JSON.stringify(isDarkTheme));
    document.body.classList.toggle('dark-theme', isDarkTheme);
    document.body.classList.toggle('small', small);
  }, [isDarkTheme, small]);

  const logout = async () => {
    try {
      await axios.post('logout', {}, { withCredentials: true });
      axios.defaults.headers.common['Authorization'] = '';
      dispatch(setAuth(false));
      setTimeout(() => {
        navigate('/');
      }, 200);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  }

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const smallSet = () => {
    setSmall(!small);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  let links;

  if (auth) {
    links = <div className='flex justify-center items-center gap-[10px] text-[16px]'>
      <SelectLanguage
        lng={lng}
        setLng={setLng} />
      <FormControlLabel
        control={<MaterialUISwitch sx={{ m: 0 }} checked={isDarkTheme} onChange={toggleTheme} />}
        label=""
      />
      <DateTimeComponent is24HourFormat={is24HourFormat} timeZone={timeZone} />
      <Badge badgeContent={userNotice ? userNotice.length : 0} color="primary">
        <FontAwesomeIcon icon={faBell}
          onClick={toggleDrawer(true)}
          className={isDarkTheme ? 'text-[18px] opacity-50 cursor-pointer info-icon '
            : 'text-[18px] opacity-50 cursor-pointer info-icon'} />
      </Badge>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}>
        <TemporaryDrawer
          userNotice={userNotice}
          refreshUserHeder={refreshUserHeder}
        />
      </Drawer>
      <div>{userInfo}</div>
      <div className='avatarka'>
        {allInfo && (
          urlImage ?
            <div
              className='w-10 h-10 cursor-pointer rounded-full'
              onClick={handleOpenUserMenu}>
              <img
                className="w-full h-full object-cover rounded-full"
                src={`${urlImage}`} alt="avatar" />
            </div> :
            userRole !== undefined && (
              <div className="relative flex justify-center items-center w-10 h-10 cursor-pointer rounded-full text-[18px] text-white info-icon"
                style={{ backgroundColor: userRole }}
                onClick={handleOpenUserMenu}>
                {allInfo.first_name[0]}{allInfo.last_name[0]}
              </div>
            ))}
        <Menu
          sx={{
            mt: '45px'
          }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu} >
          <MenuItem
            onClick={handleCloseUserMenu}
            style={{ color: 'var(--text-color)' }}>
            <div
              className='flex items-center'
              onClick={() => setShow(true)}>
              <ListItemIcon>
                <Person2Icon fontSize="small" />
              </ListItemIcon>
              <Typography textAlign="center" >{t('Profile')}</Typography>
            </div>
          </MenuItem>
          <MenuItem
            onClick={handleCloseUserMenu}
            style={{ color: 'var(--text-color)' }}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <Typography textAlign="center">{t('Setting')}</Typography>
          </MenuItem>
          <hr className='hr mt-2' />
          <MenuItem
            onClick={handleCloseUserMenu}
            style={{ color: 'var(--text-color)' }}>
            <div
              className='flex items-center'
              onClick={logout}>
              <ListItemIcon
                onClick={logout}>
                <Logout fontSize="small" />
              </ListItemIcon>
              <Typography textAlign="center">{t('LogOut')}</Typography>
            </div>
          </MenuItem>
        </Menu>
      </div>
    </div>;
  } else {
    links = <Link to={'/login'}>{t('Login')}</Link>
  }

  return (
    <div
      className='header-box bg-[var(--bg-header)] z-[1] h-[60px] relative w-full px-[30px] flex justify-between 
    items-center'>
      <div
        className='w-full flex justify-between items-center gap-7'>
        <div>
          {auth ?
            <div className='media-one flex gap-[20px] items-center'>
              <FontAwesomeIcon icon={faBarsStaggered}
                className=' opacity-50 cursor-pointer hover:opacity-90' onClick={smallSet} />
              <Search>
                <SearchIconWrapper>
                  <FontAwesomeIcon icon={faMagnifyingGlass} className='absolute left-[20px] bottom-[-14px] text-[15px] opacity-50' />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder={t('Search')}
                // inputProps={{ 'aria-label': 'search' }}
                />
              </Search>
            </div> : ''}
        </div>
        <div className="logo-info">
          <div className="user-info">
            {links}
          </div>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={show}
        onClose={() => setShow(false)}>
        {selectedUser &&
          <Profile
            refreshUserHeder={refreshUserHeder}
            selectedUser={selectedUser}
            setShow={setShow}
            baseUrl={baseUrl}
          />}
      </Drawer>
    </div>
  )
}

export default Header