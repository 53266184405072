import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { t } from 'i18next';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';

interface EditModalProps {
  setOpenEdit: any,
  teamId: any,
  refresh: any
}

export default function EditTemp({ setOpenEdit, teamId, refresh }: EditModalProps) {
  const [nameTemp, setNameTemp] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpenEdit(false);
  }

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get(`template/group/${teamId}`);
        setNameTemp(data[0].temp_name);
        refresh()
      } catch (e) {

      }
    };
    checkAuth();
  }, []);

  const updateName = async () => {
    if (!nameTemp?.trim()) {
      enqueueSnackbar('Empty', { variant: 'error' });
      return
    }
    try {
      const data: { [key: string]: any } = {
        temp_name: nameTemp,
      };
      await axios.put(`template/group/${teamId}`, data);
      enqueueSnackbar('Updated successfully', { variant: 'success' });
      refresh();
      handleClose();
    } catch (error) {
      console.error('Error updating:', error);
      enqueueSnackbar('Error updating', { variant: 'error' });
    }
  };

  return (
    <Box sx={{
      width: 320,
      height: '300%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[5px] mx-[25px] text-[var(--text-color)]'>
          <h1 className='text-[20px] text-right font-bold px-[20px] mb-[20px]'>{t('EditTemp')}</h1>
          <div>
            <input
              type="text"
              className="timesheets-input-all text-[16px] w-full p-[5px] pl-[8px] rounded-[5px]"
              value={nameTemp || ''}
              maxLength={12}
              onChange={(e) => setNameTemp(e.target.value)} />
            <p
              className='text-[8px] text-left opacity-40 pl-[5px]'>мaximum 12 characters.</p>
          </div>
          <div className='flex justify-between items-center gap-4 mt-[20px]'>
            <Button
              variant="contained"
              sx={{
                padding: '5px 20px',
                fontSize: 14,
                color: 'white !important',
                width: '100%'
              }}
              onClick={() => updateName()}>
              {t('Change')}
            </Button>
            <Button
              variant="contained"
              sx={{
                padding: '5px 20px',
                fontSize: 14,
                color: 'white !important',
                width: '100%'
              }}
              onClick={() => handleClose()}>
              {t('Close')}
            </Button>
          </div>
        </div>
      </List>
    </Box>
  )
}