import React from 'react';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan, faPlus, faBarsProgress } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router-dom";
import EditCategoryModal from '../modal/EditCategoryModal';
import DeleteCategoryModal from '../modal/DeleteCategoryModal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddCategory from '../modal/AddCategory';
import Drawer from '@mui/material/Drawer';
import { t } from 'i18next';
import Tooltip from '@mui/material/Tooltip';

interface TreeViewComponentProps {
  nodes: any[];
  onNodeSelect: (nodeId: number) => void;
  updateTree: any;
  openCheckModal: boolean;
  selectedCategoryId: any;
}

interface Node {
  id: number;
  name: string;
  task: {
    name: string,
    id: any,
    active: any
  };
  children?: Node[];
}

const TreeViewComponent: React.FC<TreeViewComponentProps> = ({ nodes, onNodeSelect, updateTree, openCheckModal, selectedCategoryId }) => {
  const [selectedCatogoryId, setSelectedCategoryId] = React.useState<number | null>(null);
  const [open, setOpen] = React.useState(false);
  const [openDel, setOpenDel] = React.useState(false);
  const [openAddCategory, setOpenAddCategory] = React.useState(false);
  const [selectCategoryName, setSelectCategoryName] = React.useState('');
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');
  const location = useLocation();

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleCloseEditModal = () => {
    setSelectedCategoryId(null);
    setOpen(false);
  };

  const handleNodeClick = (nodeId: number) => {
    const isNodeExpanded = expanded.includes(nodeId.toString());
    if (isNodeExpanded) {
      setExpanded(prevExpanded => prevExpanded.filter(id => id !== nodeId.toString()));
    } else {
      const newExpanded: string[] = [];
      newExpanded.push(nodeId.toString());
      let parent = findParent(nodes, nodeId);
      while (parent) {
        newExpanded.push(parent.id.toString());
        parent = findParent(nodes, parent.id);
      }
      setExpanded(newExpanded);
    }

    onNodeSelect(nodeId);
  };

  const findParent = (nodes: Node[], nodeId: number): Node | null => {
    for (const node of nodes) {
      if (node.id === nodeId) return null;
      if (node.children && node.children.some(child => child.id === nodeId)) return node;
      if (node.children) {
        const parent = findParent(node.children, nodeId);
        if (parent) return parent;
      }
    }
    return null;
  };

  const handleOpen = (dateId: number) => {
    setSelectedCategoryId(dateId);
    setOpen(true);
  };

  const handleOpenAddCategory = (dateId: number, dateName: string) => {
    setSelectedCategoryId(dateId);
    setSelectCategoryName(dateName);
    setOpenAddCategory(true);
  };

  const handleOpenDel = (dateId: number) => {
    setSelectedCategoryId(dateId);
    setOpenDel(true);
  };

  const renderTaskTooltip = (tasks: any[]) => (
    <div>
      {tasks
      .filter((task: any) => task.active)
      .map((task: any, index: number) => (
        <div
          className={`px-[10px] bg-[var(--btn-user-btn)] rounded-[5px] text-[14px] mb-1`}
          key={index}>
          <div className=' text-[var(--text-color)]'>{task.name}</div>
        </div>
      ))}
    </div>
  );

  const renderTree = (nodes: any[]) => (
    nodes.sort((a, b) => {
      const compareValueA = sortDirection === 'asc' ? a.name : b.name;
      const compareValueB = sortDirection === 'asc' ? b.name : a.name;
      return compareValueA.localeCompare(compareValueB);
    })
      .map(node => (
        <TreeItem key={node.id} nodeId={node.id} label={node.name} onClick={() => handleNodeClick(node.id)}
          className='relative'
          icon={node.children && node.children.length > 0 ? <ExpandMoreIcon /> : <ExpandMoreIcon
            className=' opacity-0' />}>
          {node.children && renderTree(node.children)}
          {!openCheckModal && location.pathname === '/settings' &&
            <div className='absolute top-[4px] right-[5px] flex gap-[6px]'>
             {node.task && node.task.length > 0 && (
                <Tooltip
                  title={renderTaskTooltip(node.task)}
                  placement="left-start"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: 'var(--btn-user-in)',
                      },
                    },
                  }}>
                  <FontAwesomeIcon
                    icon={faBarsProgress}
                    className='opacity-50 text-[16px] text-[var(--text-color)] mr-[5px] cursor-pointer' />
                </Tooltip>
              )}
              <div className='tranform-supervizor'>
                {node.supervisor ? (<div key={node.supervisorId} className='text-[#1976d2]'>
                  ({node.supervisor.first_name} {node.supervisor.last_name})
                </div>
                ) : null}
              </div>
              {/* <div className='flex gap-1 p-[5px] justify-end items-center mr-[10px] bord rounded-[5px] w-[300px] overflow-hidden h-[30px] cursor-pointer 
               hover:bg-[var(--bg-form)] hover:h-full hover:z-[9999] hover:flex-wrap '>
                {node.task.map((task: any, index: number) => (
                  <div
                    className={`px-[10px] bg-[var(--btn-user-btn)] rounded-[5px] text-[14px] `}
                    key={`${node.id}-${task.id}`}>
                    <div>{task.name}</div>
                  </div>
                ))}
              </div> */}
              <FontAwesomeIcon icon={faPlus}
                className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                onClick={() => handleOpenAddCategory(node.id, node.name)} />
              <FontAwesomeIcon icon={faPenToSquare}
                className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                onClick={() => handleOpen(node.id)} />
              <FontAwesomeIcon icon={faTrashCan}
                className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                onClick={() => handleOpenDel(node.id)} />
            </div>}
        </TreeItem>
      ))
  );

  const handleExpandClick = () => {
    if (expanded.length === 0) {
      const allCategoryIds = getAllNodeIds(nodes);
      setExpanded(allCategoryIds);
    } else {
      setExpanded([]);
    }
  };

  const getAllNodeIds = (nodes: Node[]): string[] => {
    const ids: string[] = [];
    const traverse = (node: Node) => {
      ids.push(node.id.toString());
      if (node.children) {
        node.children.forEach(child => traverse(child));
      }
    };
    nodes.forEach(node => traverse(node));
    return ids;
  };

  React.useEffect(() => {
    // Set all node ids as expanded initially
    if (location.pathname === '/settings') {
      const allCategoryIds = getAllNodeIds(nodes);
      setExpanded(allCategoryIds);
    }

  }, [nodes, location.pathname]);

  return (
    <>
      {!openCheckModal && location.pathname === '/settings' &&
        <div className='flex justify-between items-center'>
          <Box sx={{ mb: 0 }}>
            <Button onClick={handleExpandClick}>
              {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
            </Button>
          </Box>
          <div className='flex items-center gap-2'>
            <p className='text-[14px] font-semibold'>{t('SortBy')}:</p>
            <select
              className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
              value={sortDirection}
              onChange={(e) => setSortDirection(e.target.value as 'asc' | 'desc')}>
              <option value="asc">from A-Z</option>
              <option value="desc">from Z-A</option>
            </select>
          </div>
        </div>
      }
      {!openCheckModal && location.pathname === '/reports' &&
        <Box sx={{ mb: 0, ml: 1 }}>
          <button
            className={`w-full text-left p-[2px] hover:bg-[var(--none-button)] active:bg-[var(--none-button)] ${selectedCategoryId[0] === -1 ? 'bg-[var(--none-button)]' : ''
              }`}
            onClick={() => { onNodeSelect(-1) }}
          >All category</button>
        </Box>
      }
      {openCheckModal && location.pathname === '/settings' &&
        <Box sx={{ mb: 0, ml: 1 }}>
          <button
            className={`w-full text-left p-[2px] hover:bg-[var(--none-button)] active:bg-[var(--none-button)] ${selectedCategoryId[0] === 0 ? 'bg-[var(--none-button)]' : ''
              }`}
            onClick={() => { onNodeSelect(0) }}
          >none</button>
        </Box>
      }
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}>
        {renderTree(nodes)}
        {!openCheckModal && location.pathname === '/settings' &&
          <div className='pl-[5px]'><FontAwesomeIcon icon={faPlus}
            className='text-left text-[20px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
            onClick={() => handleOpenAddCategory(-1, '')} /></div>
        }
        <Drawer
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}>
          <EditCategoryModal
            onClose={handleCloseEditModal}
            selectedCatogoryId={selectedCatogoryId}
            setOpen={setOpen}
            updateTree={updateTree} />
        </Drawer>
        <DeleteCategoryModal
          openDel={openDel}
          setOpenDel={setOpenDel}
          selectedCatogoryId={selectedCatogoryId}
          updateTree={updateTree} />
        <Drawer
          anchor="right"
          open={openAddCategory}
          onClose={() => setOpenAddCategory(false)}>
          <AddCategory
            setOpenAddCategory={setOpenAddCategory}
            selectCategoryName={selectCategoryName}
            selectedCatogoryId={selectedCatogoryId}
            updateTree={updateTree} />
        </Drawer>
      </TreeView>
    </>
  );
};

export default TreeViewComponent;