import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface BackModalProps {
  showReject: boolean,
  setReject: any,
  rejectReport: any,
  reportId: any,
  setMessege: any,
}

const NotisReject: React.FC<BackModalProps> = ({
  showReject,
  setReject,
  rejectReport,
  reportId,
  setMessege }) => {
  const handleClose = () => {
    setReject(false);
    setMessege('');
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showReject}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showReject}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              {t('RejectMessage')}
              <TextField
                id='message-id'
                fullWidth
                onChange={(e: any) => setMessege(e.target.value)}
              />
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className='flex justify-between items-center gap-4'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => rejectReport(reportId)}>
                {t('Send')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleClose()}>
                {t('No')}
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default NotisReject;