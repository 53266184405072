import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import 'moment-timezone';
import { t } from 'i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '10px',
  backgroundColor: 'var(--background-color)',
  borderBottom: 'var(--border-input)'
}));

const StyledTableRow = styled(TableCell)(({ theme }) => ({
  color: 'var(--text-color)',
  padding: '7px',
  borderBottom: 'var(--border-input)',
  fontSize: 14
}));

interface Category {
  id: number;
  name: string;
  parentId?: number | null;
}

interface Row {
  selectedDay: string;
  dateId: number;
  date: string;
  startTime: string;
  endTime: string;
  description: string;
  tasksId: any;
  supervisorFirstName: string;
  supervisorLastName: string;
  categoryId: string;
}

interface ReportsTableProps {
  rows: Row[];
  allCategory: Category[];
  setReports: any;
  setSelectedTemplate: any;
  children?: React.ReactNode;
}

const TemplateTable: React.FC<ReportsTableProps> = ({ rows, allCategory, setReports, setSelectedTemplate }) => {

  const getCategoryPath = (categoryId: number): string => {
    const category = allCategory.find((cat: Category) => cat.id === categoryId);
    if (!category) return '';
    let path = category.name;
    let parentId = category.parentId;
    while (parentId) {
      const parentCategory = allCategory.find((cat: Category) => cat.id === parentId);
      if (parentCategory) {
        path = `${parentCategory.name} > ${path}`;
        parentId = parentCategory.parentId;
      } else {
        break;
      }
    }
    return path;
  };

  const getDayOfWeek = (dayNumber: number): string => {
    const daysOfWeek = [
      t('Monday'),
      t('Tuesday'),
      t('Wednesday'),
      t('Thursday'),
      t('Friday'),
      t('Saturday'),
      t('Sunday'),
    ];
    return daysOfWeek[dayNumber - 1] || t('Invalid Day');
  };

  const handleRemoveRow = (index: number) => {
    setReports(rows.filter((_, i) => i !== index));
    setSelectedTemplate('');
  };

  const handleRemoveAll = () => {
    setReports([]);
    setSelectedTemplate('');
  };

  const sortedRows = rows.sort((a, b) => Number(a.selectedDay) - Number(b.selectedDay));

  useEffect(() => {
  }, [rows])

  return (
    sortedRows ?
      <div className='mb-[10px] text-center' >
        <TableContainer
          component={Paper}
          style={{ boxShadow: 'none', backgroundColor: 'inherit', borderBottom: 'var(--border-input)' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell width='8%'>{t('Date')}</StyledTableCell>
                <StyledTableCell width='8%'>{t('StartTime')}</StyledTableCell>
                <StyledTableCell width='8%'>{t('EndTime')}</StyledTableCell>
                <StyledTableCell>{t('Category')}</StyledTableCell>
                <StyledTableCell>{t('Task')}</StyledTableCell>
                <StyledTableCell>{t('Description')}</StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => handleRemoveAll()}
                    sx={{
                      padding: '2px 10px',
                      fontSize: 10
                    }}>
                    {t('RemoveALL')}
                  </Button>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRows
                ?.map((row, index) => (
                  <TableRow
                    key={row.dateId || index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <StyledTableRow>{getDayOfWeek(Number(row.selectedDay))}</StyledTableRow>
                    <StyledTableRow>{row.startTime}</StyledTableRow>
                    <StyledTableRow>{row.endTime}</StyledTableRow>
                    <StyledTableRow>{getCategoryPath(Number(row.categoryId))}</StyledTableRow>
                    <StyledTableRow>{row.tasksId ? (
                      row.tasksId.map((task: any) => task.label).join(', ')
                    ) : null}</StyledTableRow>
                    <StyledTableRow>{row.description}</StyledTableRow>
                    <StyledTableRow align="right">
                      <Button
                        variant="outlined"
                        onClick={() => handleRemoveRow(index)}
                        sx={{
                          padding: '2px 5px',
                          fontSize: 9
                        }}>
                        {t('Remove')}
                      </Button>
                    </StyledTableRow>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div > : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>)
  );
};

export default TemplateTable;