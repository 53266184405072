import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import { t } from 'i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

registerLocale('en-GB', enGB);

interface User {
  id: number;
  first_name: string;
  last_name: string;
  second_name: string;
  date_of_birth: Date;
  sex: string;
  contract_start_date: Date;
  mobile_phone: string;
  work_phone: string;
  postcode: string;
  city: string;
  address: string;
  email: string;
  job_title: string;
  hoursPerWeek: number;
  min_leave_entitlement: number;
  role: {
    id: number,
    title: string
  },
  company: {
    id: number,
    title: string
  },
  supervisorId: number;
}

interface TemporaryDrawerProps {
  users: User[];
  roles: { id: number; title: string }[];
  selectedUser: User;
  refreshUsers: any;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  refreshUserHeder: any;
  access: any;
}

export default function UserReg({
  users,
  roles,
  selectedUser,
  refreshUsers,
  setShow,
  refreshUserHeder,
  access }: TemporaryDrawerProps) {

  const [userEmails, setUserEmails] = useState<{ [key: number]: string }>({});
  const [selectedUserVisor, setSelectedUserVisor] = useState<{ supervisorId?: number }>({});
  const [userName, setUserName] = useState('');
  const [userLast, setUserLast] = useState('');
  const [sex, setSex] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [workPhone, setWorkPhone] = useState('');
  const [postcode, setPostcode] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [userSecond, setUserSecond] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [minEntitlement, setMinEntitlement] = useState('');
  const [perWeek, setPerWeek] = useState('');
  const [newPassword, setNewPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [birthDate, setBirthDate] = useState<any | null>(null);
  const [contractDate, setContractDate] = useState<any | null>(null);
  const [department, setDepartment] = useState<any | null>(null);
  const [departmentAll, setDepartmentAll] = useState<any | null>(null);
  const [contract, setContract] = useState<any | null>(null);
  const [contractAll, setContractAll] = useState<any | null>(null);
  const [selectedRoles, setSelectedRoles] = useState('');
  const [active, setActive] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      if (selectedUser) {
        try {
          const { data } = await axios.get(`user/${selectedUser.id}`);
          const { data: Dep } = await axios.get(`department`);
          const { data: Cont } = await axios.get(`contract-type`);
          if (Dep) {
            const activeDepartments = Dep.filter((department: any) => department.active);
            setDepartmentAll(activeDepartments);
          }
          if (Cont) {
            const activeContract = Cont.filter((contract: any) => contract.active);
            setContractAll(activeContract);
          }
          if (data) {
            setUserSecond(data.second_name);
            setUserName(data.first_name);
            setUserLast(data.last_name);
            setSex(data.sex);
            setMobilePhone(data.mobile_phone);
            setWorkPhone(data.work_phone);
            setPostcode(data.postcode);
            setCity(data.city);
            setAddress(data.address);
            setJobTitle(data.job_title);
            setPerWeek(data.hoursPerWeek);
            setMinEntitlement(data.min_leave_entitlement);
            setBirthDate(data.date_of_birth);
            setContractDate(data.contract_start_date);
            setSelectedRoles(data.roleId);
            setDepartment(data.departmentId);
            setContract(data.contractTypeId);
            setActive(data.isActive);
          }

        } catch (e) {
        }
      }
    })();
  }, [selectedUser]);

  const handleSelectChange = (event: any) => {
    setDepartment(Number(event.target.value));
  };

  const handleChangeAcrive = () => {
    setActive(!active);
  }

  const handleSelectChangeContract = (event: any) => {
    setContract(Number(event.target.value));
  };

  const handleDateChange = (date: Date) => {
    setBirthDate(date);
  };

  const handleDateContractChange = (date: Date) => {
    setContractDate(date);
  };

  const handleInputChange = (e: any, setState: any, tire: any) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(new RegExp(`[^0-9${tire}]`, 'g'), '');
    setState(filteredValue);
  };

  const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const handleRoleChange = async (userId: number) => {
    const selectEmail = userEmails[userId] || selectedUser.email;

    if (!userName || userName.trim() === ''
      || !userLast || userLast.trim() === ''
      || !selectEmail || selectEmail.trim() === '') {
      enqueueSnackbar('Name, Last Name and Email must be provided', { variant: 'error' });
      return;
    }

    if (!EMAIL_REGEX.test(selectEmail)) {
      enqueueSnackbar('Invalid email format', { variant: 'error' });
      return;
    }

    if (newPassword) {
      if (!password || password.trim() === '' || !passwordConfirm || passwordConfirm.trim() === '') {
        enqueueSnackbar('Enter Password', { variant: 'error' });
        return;
      }
    }

    if (selectedRoles !== undefined) {
      try {
        if (newPassword) {
          if (password === passwordConfirm) {
            await axios.put(`password/${userId}`,
              {
                password: password,
                password_confirm: passwordConfirm,
              });
          } else if (password !== passwordConfirm) {
            enqueueSnackbar('Your new password and confirmation password do not match', { variant: 'error' });
            return;
          }
        }
        await axios.put(`updateUser/${userId}`,
          {
            roleId: Number(selectedRoles),
            email: selectEmail,
            first_name: userName,
            last_name: userLast,
            second_name: userSecond,
            date_of_birth: birthDate,
            sex: sex,
            contract_start_date: contractDate,
            mobile_phone: mobilePhone,
            work_phone: workPhone,
            city,
            postcode,
            address,
            job_title: jobTitle,
            min_leave_entitlement: Number(minEntitlement),
            hoursPerWeek: Number(perWeek),
            supervisorId: selectedUserVisor.supervisorId,
            departmentId: department,
            contractTypeId: contract,
            isActive: active
          });
        refreshUsers();
        setShow(false);
        refreshUserHeder();
        enqueueSnackbar('User updated successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error updating user role', { variant: 'error' });
        console.error('Error updating user role:', error);
      }
    } else {
      enqueueSnackbar('No role selected for user', { variant: 'error' });
      console.log('No role selected for user');
    }
  };

  return (
    <Box sx={{
      width: 500,
      height: '300%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[5px] mx-[25px] text-[var(--text-color)]' id='reg-user-edit'>
          <div className='flex justify-between'>
            <FormControlLabel control={<Switch
              checked={active}
              onChange={handleChangeAcrive}
              inputProps={{ 'aria-label': 'controlled' }}
            />} label="active" />
            <h1 className='text-[20px] text-right font-bold'>{t('EditUser')}</h1>
          </div>
          <div className='flex flex-col justify-evenly flex-grow' >
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('PersonalInformation')}</h2>
            <div className='flex flex-col w-full'>
              <label className='text-[10px] ml-[5px]'>{t('Name')}</label>
              <input
                type="text"
                className="w-full rounded-[5px] edit-input"
                value={userName ? userName : ''}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </div>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('LastName')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input"
                  value={userLast ? userLast : ''}
                  onChange={(e) => {
                    setUserLast(e.target.value);
                  }}
                />
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('SecondName')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input"
                  value={userSecond ? userSecond : ''}
                  onChange={(e) => {
                    setUserSecond(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col'>
              <label className='text-[10px] ml-[5px]'>{t('Email')}</label>
              <input
                type="text"
                className="rounded-[5px] edit-input"
                value={(userEmails[selectedUser.id] === '' || selectedUser.email === '') ? '' : userEmails[selectedUser.id] || selectedUser.email}
                onChange={(e) => {
                  const newValue = e.target.value;
                  const updatedValue = newValue.trim() !== '' ? newValue : '';
                  setUserEmails((prevEmails) => ({ ...prevEmails, [selectedUser.id]: updatedValue }));
                }}
              />
            </div>
            <div className="grid grid-cols-2 gap-1 mb-[5px]">
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('DateOfBirth')}</label>
                <DatePicker
                  selected={birthDate ? new Date(birthDate) : null}
                  onChange={(date: any) => handleDateChange(date)}
                  className="timesheets-input-all text-right text-[18px] w-full p-[6px] rounded-[5px]"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                  locale="en-GB"
                />
              </div>
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('Sex')}</label>
                <select
                  className="edit-select p-[10px] rounded-[5px] edit-input"
                  value={sex ? sex : ''}
                  onChange={(e) => setSex(e.target.value)}>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>
            </div>
            <hr className='hr mt-1' />
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('WorkInformation')}</h2>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('')}Job Title</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input"
                  value={jobTitle ? jobTitle : ''}
                  onChange={(e) => setJobTitle(e.target.value)} />
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('ContractStartDate')}</label>
                <DatePicker
                  selected={contractDate ? new Date(contractDate) : null}
                  onChange={(date: any) => handleDateContractChange(date)}
                  className="timesheets-input-all text-right text-[18px] w-full p-[6px] rounded-[5px]"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                  locale="en-GB"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-1 mb-[5px]">
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('Department')}</label>
                <select
                  className="edit-select p-[10px] rounded-[5px] edit-input"
                  value={department || 'none'}
                  onChange={handleSelectChange}>
                  <option value="none">none</option>
                  {departmentAll?.map((department: any) => (
                    <option key={department.id} value={department.id}>
                      {department.title}
                    </option>))}
                </select>
              </div>
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('ContractType')}</label>
                <select
                  className="edit-select p-[10px] rounded-[5px] edit-input"
                  value={contract || 'none'}
                  onChange={handleSelectChangeContract}>
                  <option value="none">none</option>
                  {contractAll?.map((contract: any) => (
                    <option key={contract.id} value={contract.id}>
                      {contract.title}
                    </option>))}
                </select>
              </div>
            </div>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('ContractedHoursPerWeek')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input text-right"
                  value={perWeek ? perWeek : ''}
                  onChange={(e) => handleInputChange(e, setPerWeek, '')} />
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('MinLeaveEntitlement')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input text-right"
                  value={minEntitlement ? minEntitlement : ''}
                  onChange={(e) => handleInputChange(e, setMinEntitlement, '')} />
              </div>
            </div>
            <hr className='hr mt-1' />
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('ContactInformation')}</h2>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('MobilePhone')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input"
                  value={mobilePhone ? mobilePhone : ''}
                  onChange={(e) => handleInputChange(e, setMobilePhone, '-')} />
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('WorkPhone')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input"
                  value={workPhone ? workPhone : ''}
                  onChange={(e) => handleInputChange(e, setWorkPhone, '-')} />
              </div>
            </div>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-[35%]'>
                <label className='text-[10px] ml-[5px]'>{t('Postcode')}</label>
                <input type="text"
                  id='postcode-user'
                  className="w-full rounded-[5px] edit-input"
                  value={postcode ? postcode : ''}
                  onChange={(e) => handleInputChange(e, setPostcode, '')} />
              </div>
              <div className='flex flex-col w-[65%]'>
                <label className='text-[10px] ml-[5px]'>{t('City')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input"
                  value={city ? city : ''}
                  onChange={(e) => setCity(e.target.value)} />
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('Address')}</label>
                <input type="text"
                  className="rounded-[5px] edit-input"
                  value={address ? address : ''}
                  onChange={(e) => setAddress(e.target.value)} />
              </div>
            </div>
            <hr className='hr mt-1' />
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('OtherSettings')}</h2>
            <div className="grid grid-cols-2 gap-1 mb-[5px]">
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('TimeZone')}</label>
                <select className="edit-select p-[10px] rounded-[5px] edit-input">
                  <option value="none" disabled></option>
                </select>
              </div>
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('NotificationLanguage')}</label>
                <select className="edit-select p-[10px] rounded-[5px] edit-input">
                  <option value="none" disabled></option>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-1">
              {access?.users_do > 2 ? <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('Role')}</label>
                <select className="edit-select p-[10px] rounded-[5px] edit-input"
                  value={selectedRoles}
                  onChange={(e) => setSelectedRoles(e.target.value)}>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.title}
                    </option>))}
                </select>
              </div> : ''}
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('Supervisor')}</label>
                <select
                  className="edit-select rounded-[5px] edit-input"
                  onChange={(e) => setSelectedUserVisor({ supervisorId: Number(e.target.value) })}>
                  <option value=''>
                    {selectedUser.supervisorId
                      ? `${users.find(user => user.id === selectedUser.supervisorId)?.first_name || 'none'} ${users.find(user => user.id === selectedUser.supervisorId)?.last_name || ''}`
                      : 'none'}
                  </option>
                  {users
                    .filter(user => user.id !== selectedUser.supervisorId)
                    .map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.first_name} {user.last_name}
                      </option>
                    ))}
                  {selectedUser.supervisorId !== null && selectedUser.supervisorId !== undefined && (
                    <option key='none' value="null">none</option>
                  )}
                </select>
              </div>
            </div>
            {access?.users_do > 2 ?
              <>
                {!newPassword &&
                  <div className='mt-[10px] text-right'>
                    <button
                      className='bg-[var(--btn-user-btn)] text-[var(--text-color)] border-none text-[16px] py-[5px] px-[15px] cursor-pointer rounded-[5px] mt-[5px] hover:bg-[var(--btn-user-hover)]'
                      onClick={() => setNewPassword(true)}>{t('ChangePassword')}</button>
                  </div>}
                {newPassword &&
                  <div className='flex gap-[5px] mt-[10px]'>
                    <input placeholder="Enter new Password"
                      type="password"
                      id="password"
                      className="w-full rounded-[5px] edit-input"
                      onChange={e => setPassword(e.target.value)} />
                    <input placeholder="Confirm Password"
                      type="password"
                      id="confirm_pwd"
                      className="w-full rounded-[5px] edit-input"
                      onChange={e => setPasswordConfirm(e.target.value)} />
                  </div>}
              </>
              : ''}
            <div className='flex mt-[20px] justify-center items-center gap-[10px]'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleRoleChange(selectedUser.id)}>
                {t('Change')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => setShow(false)}>
                {t('Cancel')}
              </Button>
            </div>
          </div>
        </div>
      </List>
    </Box>
  )
}