import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import UserEdit from './modal/UserEdit';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import UsersTable from './usersComponents/UsersTable';
import UserReg from './modal/UserReg';
import Drawer from '@mui/material/Drawer';
import Breadcrums from './util/Breadcrums';
import { useLocation } from "react-router-dom";
import { t } from 'i18next';
import DeleteModalUser from './modal/DeleteModalUser';
import UsersTableNotActive from './usersComponents/UsersTableNotActive';
import Button from '@mui/material/Button';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

interface User {
  id: number;
  first_name: string;
  last_name: string;
  second_name: string;
  email: string;
  date_of_birth: Date;
  contract_start_date: Date;
  sex: string;
  mobile_phone: string;
  work_phone: string;
  postcode: string;
  city: string;
  address: string;
  job_title: string;
  hoursPerWeek: number;
  min_leave_entitlement: number;
  role: {
    id: number,
    title: string
  },
  company: {
    id: number,
    title: string
  },
  supervisorId: number;
  last_login: any;
}

interface UsersProps {
  refreshUserHeder: any,
  access: any,
  baseUrl: string
}

const Users: React.FC<UsersProps> = ({ refreshUserHeder, access, baseUrl }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [usersSuper, setUsersSuper] = useState<User[]>([]);
  const [usersNotActive, setUsersNotActive] = useState<User[]>([]);
  const [originalUsers, setOriginalUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<{ id: number; title: string }[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const auth = useSelector((state: RootState) => state.auth.value);
  const [selectedRoleFilter, setSelectedRoleFilter] = useState<number | null>(null);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const dispatch = useDispatch();
  const [locationName, setLocationName] = useState('');
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [viewLastLogin, setViewLastLogin] = useState(false);
  const [viewContractTitle, setViewContractTitle] = useState(false);
  const [viewJobTitle, setViewJobTitle] = useState(false);

  // add localStorage viewMenu
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.value;
    if (selectedOption === 'lastLogin') {
      const newViewLastLogin = !viewLastLogin;
      setViewLastLogin(newViewLastLogin);
      localStorage.setItem('viewLastLogin', JSON.stringify(newViewLastLogin));
    } else if (selectedOption === 'ContractTitle') {
      const newViewJobTitle = !viewContractTitle;
      setViewContractTitle(newViewJobTitle);
      localStorage.setItem('viewContractTitle', JSON.stringify(newViewJobTitle));
    } else if (selectedOption === 'JobTitle') {
      const newviewJobTitle = !viewJobTitle;
      setViewJobTitle(newviewJobTitle);
      localStorage.setItem('viewJobTitle', JSON.stringify(newviewJobTitle));
    }
  };

  useEffect(() => {
    const lastLoginState = localStorage.getItem('viewLastLogin');
    const ContractState = localStorage.getItem('viewContractTitle');
    const jobTitleState = localStorage.getItem('viewJobTitle');

    if (lastLoginState !== null) {
      setViewLastLogin(JSON.parse(lastLoginState));
    }

    if (ContractState !== null) {
      setViewContractTitle(JSON.parse(ContractState));
    }

    if (jobTitleState !== null) {
      setViewJobTitle(JSON.parse(jobTitleState));
    }
  }, []);

  //open UserEdit
  const handleShow = (user: User) => {
    setSelectedUser(user);
    setShow(true);
  };

  //open UserDelete
  const handleShowDelete = (user: User) => {
    setSelectedUser(user);
    setDeleteShow(true);
  };

  //refresh users
  const refreshUsers = async () => {
    try {
      const { data: usersData } = await axios.get('users');
      const { data: usersActiveData } = await axios.get('users/inactive');
      setUsers(usersData);
      setOriginalUsers(usersData);
      setUsersNotActive(usersActiveData);
      setUsersSuper(usersData);
    } catch (error: any) {
      console.log('error users');
    }
  }
  //get all data and check user
  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get('user');
        const { data: usersData } = await axios.get('users');
        const { data: usersActiveData } = await axios.get('users/inactive');
        const { data: rolesData } = await axios.get('roles');
        setUsers(usersData);
        setUsersSuper(usersData);
        setOriginalUsers(usersData);
        setUsersNotActive(usersActiveData);
        setLocationName(location.pathname);
        setRoles(rolesData);
        if (data) {
          dispatch(setAuth(true));
        }
      } catch (e) {
        dispatch(setAuth(false));
      }
    })();
  }, [dispatch, location.pathname]);

  return (
    <>
      <Breadcrums locationName={locationName} />
      {auth && access.users_get > 0 ? (
        <>
          <div className="bg-[var(--bg-form)] w-full p-[15px] mb-[15px] rounded-[5px]">
            <div className='flex justify-between items-center'>
              <div className="flex gap-3 justify-start items-center w-full">
                {access.users_do > 0 ? (
                  <Button
                    //open UserReg
                    variant="contained"
                    endIcon={<PersonAddAlt1Icon
                      sx={{ color: 'white !important' }} />}
                    sx={{
                      padding: '5px 20px',
                      fontSize: 14,
                      color: 'white !important'
                    }}
                    onClick={() => setShowModalAdd(true)}>
                    {t('AddUser')}
                  </Button>
                ) : ''}
                <input
                  //fillter users 
                  type="text"
                  className='w-[60%] text-[16px] input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] py-[5px] px-[10px] rounded-[5px]'
                  placeholder={t('fillter')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const searchText = e.target.value.toLowerCase();
                    const filteredUsers = originalUsers.filter(user =>
                      user.last_name.toLowerCase().includes(searchText) ||
                      user.first_name.toLowerCase().includes(searchText)
                    );
                    setUsers(filteredUsers);
                  }} />
              </div>
              <div className='flex items-center justify-end gap-[10px] w-full'>
                <p className='media-one text-[14px] font-semibold'>{t('SortBy')}:</p>
                <select
                  // select role 
                  className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
                  value={selectedRoleFilter !== null ? selectedRoleFilter : 'all'}
                  onChange={(e) => setSelectedRoleFilter(e.target.value === 'all' ? null : Number(e.target.value))}>
                  <option value="all">all roles</option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='admin-box flex flex-col justify-start p-[15px] rounded-[5px] mb-3'>
            <div className='admin-box-users'>
              <div className='flex justify-between items-center'>
                <div className="flex gap-4  my-[10px] justify-start items-center w-[80%]">
                  <h3 className='text-[18px]'>{t('UsersList')}:</h3>
                </div>
                <div className='flex gap-[40px] items-center'>
                  <div className='flex gap-[10px] items-center'>
                    <p>View:</p>
                    <select
                      value={'choose'}
                      onChange={handleSelectChange}
                      className='w-full text-[14px] py-[2px] px-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'>
                      <option value="choose" disabled></option>
                      <option value="lastLogin">{viewLastLogin ? '+' : '-'} Last Login</option>
                      <option value="ContractTitle">{viewContractTitle ? '+' : '-'} Contract Type</option>
                      <option value="JobTitle">{viewJobTitle ? '+' : '-'} Job Title</option>
                    </select>
                  </div>
                  <div className="flex gap-[5px] mr-[10px] items-center text-[16px]">
                    <FontAwesomeIcon icon={faUserGroup} className='text-[16px] opacity-50 text-[var(--text-color)]' />
                    <div className="user-count-view flex gap-[5px]"><span className='user-count'>{users?.length}</span> {t('users')}</div>
                  </div>
                </div>
              </div>
              <div className='my-[10px]'>
                {/* Check users lenght to 0  */}
                {Array.isArray(users) && users.length > 0 ? (
                  <UsersTable
                    access={access}
                    users={users}
                    usersSuper={usersSuper}
                    handleShow={handleShow}
                    handleShowDelete={handleShowDelete}
                    selectedRoleFilter={selectedRoleFilter}
                    baseUrl={baseUrl}
                    viewLastLogin={viewLastLogin}
                    viewContractTitle={viewContractTitle}
                    viewJobTitle={viewJobTitle}
                    refreshUsers={refreshUsers}
                    roles={roles}
                  />
                ) : (
                  <div className="user-count-view">No users found...</div>
                )}
                {access.description === -1 &&
                  <div className='my-[10px]'>
                    <div className="flex gap-4  my-[10px] justify-start items-center w-[80%]">
                      <h3 className='text-[18px]'>{t('Not Active User List')}:</h3>
                    </div>
                    {/* Check users lenght to 0  */}
                    {Array.isArray(users) && users.length > 0 ? (
                      <UsersTableNotActive
                        access={access}
                        users={usersNotActive}
                        userAll={users}
                        handleShow={handleShow}
                        handleShowDelete={handleShowDelete}
                        baseUrl={baseUrl}
                      />
                    ) : (
                      <div className="user-count-view">No users found...</div>
                    )}
                  </div>}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      )}
      <Drawer
        anchor="right"
        open={show}
        onClose={() => setShow(false)}>
        {selectedUser &&
          <UserEdit
            users={usersSuper}
            roles={roles}
            selectedUser={selectedUser}
            refreshUsers={refreshUsers}
            setShow={setShow}
            refreshUserHeder={refreshUserHeder}
            access={access}
          />}
      </Drawer>
      <Drawer
        anchor="right"
        open={showModalAdd}
        onClose={() => setShowModalAdd(false)}>
        <UserReg setShowModalAdd={setShowModalAdd} refreshUsers={refreshUsers} />
      </Drawer>
      {deleteShow && (
        <DeleteModalUser open={deleteShow} setOpen={setDeleteShow} selectedUser={selectedUser} refreshUsers={refreshUsers} />
      )}
    </>
  );
};

export default Users;