import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4
};

interface EditModalProps {
  openEdit: boolean;
  selectedId: any;
  setOpenEdit: any;
  refresh: any;
  setCheckOpenModal: any;
  typeName: any;
  typeBack: any;
  typeBackAll: any;
  fieldName: 'name' | 'title';
}

const EditModalAll: React.FC<EditModalProps> = ({
  openEdit,
  selectedId,
  setOpenEdit,
  refresh,
  setCheckOpenModal,
  typeName,
  typeBack,
  typeBackAll,
  fieldName }) => {
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);
  const handleClose = () => {
    setOpenEdit(false)
    setCheckOpenModal(false);
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeAcrive = () => {
    setActive(!active);
  }

  const updateName = async () => {
    if (!name?.trim()) {
      enqueueSnackbar('Empty', { variant: 'error' });
      return
    }
    try {
      const data: { [key: string]: any } = {
        [fieldName]: name,
        active: active === null ? true : active
      };
      await axios.put(`/${typeBackAll}/${selectedId}`, data);
      enqueueSnackbar('Updated successfully', { variant: 'success' });
      handleClose();
      setCheckOpenModal(false);
      refresh();
    } catch (error) {
      console.error('Error updating:', error);
      enqueueSnackbar('Error updating', { variant: 'error' });
    }
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await axios.get(`${typeBack}/${selectedId}`);
        if (response) {
          setName(response.data[fieldName]);
          setActive(response.data.active)
        }
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };

    if (selectedId !== null) {
      fetchTasks();
    }
  }, [selectedId]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openEdit}
        onClose={() => {
          handleClose()
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Fade in={openEdit}>
          <Box sx={style}>
            <div className=''>
              <div className='flex flex-col mb-3'>
                <div className='flex justify-between items-center'>
                  <div>{`${t('ChangeName')} ${t(typeName)}`}:</div>
                  <FormControlLabel control={<Switch
                    checked={active === null ? true : active}
                    onChange={handleChangeAcrive}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />} label="active" />
                </div>
                <input type="text"
                  value={name}
                  className='w-full bg-[var(--bg-input)] p-1 rounded-[5px] edit-reports-input'
                  onChange={(e) => setName(e.target.value)} />
              </div>
            </div>
            <div className='flex justify-between items-center gap-4 mt-3'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => updateName()}>
                {t('Change')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleClose()}>
                {t('Close')}
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default EditModalAll;