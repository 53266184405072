import axios from "axios";

axios.defaults.baseURL = 'https://timesheets.alegros.co.uk:8089/api/';

let refresh = false;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401 && !refresh) {
      refresh = true;
      try {
        const response = await axios.post('refresh', {}, { withCredentials: true });
        if (response.status === 200) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
          return axios(error.config);
        }
      } catch (refreshError) {
      }
    }
    refresh = false;
    return Promise.reject(error);
  }
);
