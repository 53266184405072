import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import DatePicker, { registerLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB';
import { t } from 'i18next';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

registerLocale('en-GB', enGB);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface User {
  id: number;
  first_name: string;
  last_name: string;
  second_name: string;
  date_of_birth: Date;
  sex: string;
  contract_start_date: Date;
  mobile_phone: string;
  work_phone: string;
  postcode: string;
  city: string;
  address: string;
  email: string;
  job_title: string;
  hoursPerWeek: number;
  min_leave_entitlement: number;
  role: {
    id: number,
    title: string
  },
  company: {
    id: number,
    title: string
  },
  supervisorId: number;
  avatarUrl?: string;
}

interface ProfileProps {
  selectedUser: User;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  refreshUserHeder: any;
  baseUrl: string;
}

export default function Profile({
  refreshUserHeder,
  selectedUser,
  setShow,
  baseUrl }: ProfileProps) {

  const [userEmails, setUserEmails] = useState('');
  const [userName, setUserName] = useState('');
  const [userLast, setUserLast] = useState('');
  const [sex, setSex] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [workPhone, setWorkPhone] = useState('');
  const [postcode, setPostcode] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [userSecond, setUserSecond] = useState('');
  const [birthDate, setBirthDate] = useState<any | null>(null);
  const [newPassword, setNewPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [urlImage, setUrlImage] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      if (selectedUser) {
        try {
          const hostname = window.location.hostname;
          const { data } = await axios.get(`user/${selectedUser.id}`);
          if (data) {
            setUserSecond(data.second_name);
            setUserName(data.first_name);
            setUserLast(data.last_name);
            setSex(data.sex);
            setMobilePhone(data.mobile_phone);
            setWorkPhone(data.work_phone);
            setPostcode(data.postcode);
            setCity(data.city);
            setAddress(data.address);
            setBirthDate(data.date_of_birth);
            setUserEmails(data.email);
            if (data.avatarUrl) {
              if (hostname === 'localhost') {
                const baseUrl = 'http://localhost:8000/';
                setUrlImage(`${baseUrl}${data.avatarUrl}`);
              } else {
                const baseUrl = 'https://timesheets.alegros.co.uk:8089/';
                setUrlImage(`${baseUrl}${data.avatarUrl}`);
              }
            }
          }
        } catch (e) {
        }
      }
    })();
  }, [selectedUser]);

  const handleDateChange = (date: Date) => {
    setBirthDate(date);
  };

  const handleSubmit = async () => {
    if (file) {
      const fileType = file.type;
      const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      const maxSize = 1 * 1024 * 1024;
      if (!allowedTypes.includes(fileType)) {
        enqueueSnackbar('Error: File must be in PNG, JPG, or JPEG format', { variant: 'error' });
        return;
      }
      if (file.size > maxSize) {
        enqueueSnackbar('Error: File size must be less than 1MB', { variant: 'error' });
        return;
      }
      try {
        const formData = new FormData();
        formData.append('avatar', file);
        await axios.post('upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const { data } = await axios.get(`user/${selectedUser.id}`);
        if (data) {
          setUrlImage(`${baseUrl}${data.avatarUrl}`);
          setFile(null);
        }
        refreshUserHeder();
        enqueueSnackbar('Uploading success', { variant: 'success' });
      } catch (error) {
        console.error('Error uploading file:', error);
        enqueueSnackbar('Error uploading file', { variant: 'error' });
      }
    }
  };

  const handleDeleteAvatar = async (e: any) => {
    await axios.delete(`deleteAvatar`);
    setUrlImage('');
    setFile(null);
    refreshUserHeder();
  }

  const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const handleInputChange = (e: any, setState: any, tire: any) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(new RegExp(`[^0-9${tire}]`, 'g'), '');
    setState(filteredValue);
  };

  const handleRoleChange = async (userId: number) => {
    if (!userName || userName.trim() === ''
      || !userLast || userLast.trim() === '') {
      enqueueSnackbar('Name, Last Neme and Email must be provided', { variant: 'error' });
      return;
    }
    if (!EMAIL_REGEX.test(userEmails)) {
      enqueueSnackbar('Invalid email format', { variant: 'error' });
      return;
    }
    try {
      if (newPassword) {
        if (password === passwordConfirm) {
          await axios.put(`password/${selectedUser.id}`, {
            password: password,
            password_confirm: passwordConfirm,
          });
        } else {
          enqueueSnackbar('Your new password and confirmation password do not match', { variant: 'error' });
          return;
        }
      }
      await axios.put(`updateUser/${selectedUser.id}`, {
        first_name: userName,
        last_name: userLast,
        second_name: userSecond,
        date_of_birth: birthDate,
        sex: sex,
        mobile_phone: mobilePhone,
        work_phone: workPhone,
        city,
        postcode,
        address,
        email: userEmails,
      });
      if (file) {
        handleSubmit();
      }
      refreshUserHeder();
      setShow(false);
      enqueueSnackbar('User updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error updating user role', { variant: 'error' });
      console.error('Error updating user role:', error);
    }
  };

  return (
    <Box sx={{
      width: 500,
      height: '200%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[5px] mx-[25px] text-[var(--text-color)]' id='reg-user-edit'>
          <h1 className='text-[20px] text-right font-bold'>{t('Profile')}</h1>
          <div className='flex flex-col justify-evenly flex-grow' >
            <div className='flex flex-col w-full'>
              <div className='flex justify-center'>
                {urlImage ?
                  <div
                    className='w-[100px] h-[100px] mb-[10px] relative'>
                    <img
                      className="w-full h-full object-cover rounded-full timesheet-edit-input"
                      src={urlImage} alt="avatar" />
                    <FontAwesomeIcon
                      icon={faXmark}
                      className='absolute top-0 right-0 cursor-pointer hover:text-red-500 hover:rotate-180 hover:transform hover:transition-transform duration-300'
                      onClick={handleDeleteAvatar} />
                  </div>
                  : ''}
              </div>
              <div
                className='flex justify-center gap-[10px] items-center mx-[10px]'>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  sx={{
                    padding: '2px 10px',
                    color: '#e5e7eb !important'
                  }}>
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    accept=".png, .jpeg, .jpg"
                    onChange={(e) => {
                      setFile(e.target.files ? e.target.files[0] : null);
                    }} />
                </Button>
                {file && (
                  <>
                    <div className='flex justify-center mt-[10px] text-[14px]'>
                      <span> {file.name}</span>
                    </div>
                    <FontAwesomeIcon
                      icon={faXmark}
                      className='cursor-pointer hover:text-red-500 hover:rotate-180 hover:transform hover:transition-transform duration-300'
                      onClick={() => setFile(null)} />
                  </>
                )}
              </div>
            </div>
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('PersonalInformation')}</h2>
            <div className='flex flex-col w-full'>
              <label className='text-[10px] ml-[5px]'>{t('Name')}</label>
              <input
                type="text"
                className="w-full rounded-[5px] edit-input"
                value={userName ? userName : ''}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
            </div>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('LastName')}</label>
                <input type="text"
                  className="rounded-[5px] edit-input"
                  value={userLast ? userLast : ''}
                  onChange={(e) => {
                    setUserLast(e.target.value);
                  }}
                />
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('SecondName')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input"
                  value={userSecond ? userSecond : ''}
                  onChange={(e) => {
                    setUserSecond(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col'>
              <label className='text-[10px] ml-[5px]'>{t('Email')}</label>
              <input
                type="text"
                value={userEmails ? userEmails : ''}
                className="rounded-[5px] edit-input"
                onChange={(e) => {
                  setUserEmails(e.target.value);
                }}
              />
            </div>
            <div className="grid grid-cols-2 gap-1 mb-[5px]">
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('DateOfBirth')}</label>
                <DatePicker
                  selected={birthDate ? new Date(birthDate) : null}
                  onChange={(date: any) => handleDateChange(date)}
                  className="timesheets-input-all text-right text-[18px] w-full p-[6px] rounded-[5px]"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                  locale="en-GB"
                />
              </div>
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('Sex')}</label>
                <select
                  className="edit-select p-[10px] rounded-[5px] edit-input"
                  value={sex ? sex : ''}
                  onChange={(e) => setSex(e.target.value)}>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>
            </div>
            <hr className='hr mt-1' />
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('ContactInformation')}</h2>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('MobilePhone')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input"
                  value={mobilePhone ? mobilePhone : ''}
                  onChange={(e) => handleInputChange(e, setMobilePhone, '-')} />
              </div>
              <div className='flex flex-col w-full'>
                <label className='text-[10px] ml-[5px]'>{t('WorkPhone')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input"
                  value={workPhone ? workPhone : ''}
                  onChange={(e) => handleInputChange(e, setWorkPhone, '-')} />
              </div>
            </div>
            <div className='flex gap-[5px]'>
              <div className='flex flex-col w-[35%]'>
                <label className='text-[10px] ml-[5px]'>{t('Postcode')}</label>
                <input type="text"
                  id='postcode-user'
                  className="w-full rounded-[5px] edit-input"
                  value={postcode ? postcode : ''}
                  onChange={(e) => handleInputChange(e, setPostcode, '')} />
              </div>
              <div className='flex flex-col w-[65%]'>
                <label className='text-[10px] ml-[5px]'>{t('City')}</label>
                <input type="text"
                  className="w-full rounded-[5px] edit-input"
                  value={city ? city : ''}
                  onChange={(e) => setCity(e.target.value)} />
              </div>
            </div>
            <div className='flex flex-col w-full'>
              <label className='text-[10px] ml-[5px]'>{t('Address')}</label>
              <input type="text"
                className="rounded-[5px] edit-input"
                value={address ? address : ''}
                onChange={(e) => setAddress(e.target.value)} />
            </div>
            <hr className='hr mt-1' />
            <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('OtherSettings')}</h2>
            <div className="grid grid-cols-2 gap-1 mb-[5px]">
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('TimeZone')}</label>
                <select className="edit-select p-[10px] rounded-[5px] edit-input">
                  <option value="none" disabled></option>
                </select>
              </div>
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('NotificationLanguage')}</label>
                <select className="edit-select p-[10px] rounded-[5px] edit-input">
                  <option value="none" disabled></option>
                </select>
              </div>
            </div>
            {!newPassword &&
              <div className='mt-[10px] text-right'>
                <button
                  className='bg-[var(--btn-user-btn)] text-[var(--text-color)] border-none text-[16px] py-[5px] px-[15px] cursor-pointer rounded-[5px] mt-[5px] hover:bg-[var(--btn-user-hover)]'
                  onClick={() => setNewPassword(true)}>{t('ChangePassword')}</button>
              </div>}
            {newPassword &&
              <div className='flex gap-[5px] mt-[10px]'>
                <input placeholder="Enter new Password"
                  type="password"
                  id="password"
                  className="w-full rounded-[5px] edit-input"
                  onChange={e => setPassword(e.target.value)} />
                <input placeholder="Confirm Password"
                  type="password"
                  id="confirm_pwd"
                  className="w-full rounded-[5px] edit-input"
                  onChange={e => setPasswordConfirm(e.target.value)} />
              </div>}
            <div className='flex mt-[20px] justify-center items-center gap-[10px]'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleRoleChange(selectedUser.id)}>
                {t('Change')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => setShow(false)}>
                {t('Cancel')}
              </Button>
            </div>
          </div>
        </div>
      </List>
    </Box>
  )
}
