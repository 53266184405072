import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import SelectTaskEdit from '../adminComponents/SelectTaskEdit';
import List from '@mui/material/List';
import { t } from 'i18next';
import Button from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { transformToHierarchy } from '../util/tree';
import TreeViewEditModal from '../util/TreeViewEditModal';
interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: {
    id: number,
    title: string
  },
  company: {
    id: number,
    title: string
  },
  supervisorId: number;
}

interface EditModalProps {
  selectedCatogoryId: any;
  setOpen: any;
  updateTree: any;
  onClose: () => void;
}

const EditCategoryModal: React.FC<EditModalProps> = ({ selectedCatogoryId, setOpen, updateTree, onClose }) => {
  const [selectedSupervisor, setSelectedSupervisor] = useState<any | null>(null);
  const [categoryName, setCategoryName] = useState('');
  const [categoryNameEdit, setCategoryNameEdit] = useState<any[]>([]);
  const [allCategoryInfo, setAllCategoryInfo] = useState<any | null>(null);
  const [categorySuper, setCategorySuper] = useState();
  const [selectedTask, setSelectedTask] = useState<any[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [category, setCategory] = useState({});
  const [categoryEdit, setCategoryEdit] = useState<any[]>([]);
  const [isPasteDisabled, setIsPasteDisabled] = useState<boolean>(true);
  const [selectedCategoryIdEdit, setSelectedCategoryIdEdit] = useState<number[]>([]);
  const [isCategoryListVisible, setCategoryListVisible] = useState(false);
  const [prevSelectedCategoryId, setPrevSelectedCategoryId] = useState<number[]>([]);
  const [allCategory, setAllCategory] = useState<any[]>([]);
  const transformToHierarchyCallback = useCallback(transformToHierarchy, []);
  const handleClose = () => {
    setSelectedSupervisor(null);
    setOpen(false);
    onClose();
  };
  const { enqueueSnackbar } = useSnackbar();

  const handleClickCategoryBox = (event: React.MouseEvent) => {
    setCategoryListVisible(!isCategoryListVisible);
    if (isCategoryListVisible) {
      setSelectedCategoryIdEdit(prevSelectedCategoryId)
    }
    setPrevSelectedCategoryId(selectedCategoryIdEdit);
    event.stopPropagation();
  };

  const handleCategoryListClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleClickAddCategoryId = (selectedId: number) => {
    const selectedCategory = allCategory?.find(node => node.id === selectedId);
    if (selectedCategory) {
      setAllCategoryInfo(selectedId)
      setCategoryNameEdit(selectedCategory.name)
      setCategoryListVisible(false);
    } else {
      setAllCategoryInfo(null);
      setCategoryNameEdit(['none'])
      setCategoryListVisible(false);
    }
  };

  const handleNodeSelect = (nodeId: number) => {
    setSelectedCategoryIdEdit([nodeId])
  };

  useEffect(() => {
    const copiedTasks = localStorage.getItem('copiedTasks');
    setIsPasteDisabled(!copiedTasks);
  }, []);

  const updateCategory = async (categoryId: any, newName: any, supervisor: any) => {
    if (!categoryName?.trim()) {
      enqueueSnackbar('Empty category', { variant: 'error' });
      return
    }
    try {
      const response = await axios.put(`/category/${categoryId}`, {
        name: newName,
        supervisorId: supervisor,
        taskId: selectedTask.map((task: any) => task.value),
        parentId: allCategoryInfo
      });
      if (response.status === 200 || response.status === 201) {
        enqueueSnackbar('Category updated successfully', { variant: 'success' });
        setCategorySuper(supervisor);
        handleClose();
        updateTree();
        setSelectedSupervisor(categorySuper);
      } else {
        enqueueSnackbar(`child cant be parent`, { variant: 'error' });
        return
      }
    } catch (error) {
      enqueueSnackbar('Error updating', { variant: 'error' });
    }
  };

  const handleCopyTasks = () => {
    localStorage.setItem('copiedTasks', JSON.stringify(selectedTask));
    setIsPasteDisabled(false);
    enqueueSnackbar('Tasks copied successfully', { variant: 'success' });
  };

  const handlePasteTasks = () => {
    const copiedTasks = localStorage.getItem('copiedTasks');
    if (copiedTasks) {
      setSelectedTask(JSON.parse(copiedTasks));
      enqueueSnackbar('Tasks pasted successfully', { variant: 'success' });
    } else {
      enqueueSnackbar('No tasks to paste', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (selectedCatogoryId !== null) {
      (async () => {
        try {
          const { data: category } = await axios.get(`category`);
          setAllCategory(category);
          const { data: categoryName } = await axios.get(`category/${selectedCatogoryId}`);
          const { data: usersData } = await axios.get('users');
          setUsers(usersData);
          const hierarchyData = transformToHierarchyCallback(category);
          setCategoryEdit(hierarchyData);
          setCategoryName(categoryName.name);
          setCategoryNameEdit(categoryName.parentId);
          setAllCategoryInfo(categoryName.parentId);
          if (allCategoryInfo) {
            const { data } = await axios.get(`category/${allCategoryInfo}`);
            setCategoryNameEdit(data.name);
          }
          setCategorySuper(categoryName.supervisorId);
          setSelectedSupervisor(categorySuper);
          setCategory(categoryName);
        } catch (e) {
        }
      })();
    }
  }, [selectedCatogoryId, categorySuper]);

  useEffect(() => {
    const handleDocumentClick = () => {
      if (prevSelectedCategoryId[0] !== selectedCategoryIdEdit[0]) {
        setSelectedCategoryIdEdit(prevSelectedCategoryId);
      } else {
        setSelectedCategoryIdEdit(selectedCategoryIdEdit)
      }
      setCategoryListVisible(false);
    };
    if (isCategoryListVisible) {
      document.addEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isCategoryListVisible, prevSelectedCategoryId, selectedCategoryIdEdit]);

  return (
    <Box sx={{
      width: 480,
      height: '100%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div
          className='my-[5px] mx-[25px] text-[var(--text-color)]'>
          <h1 className='text-[20px] text-right font-bold mb-[30px]'>{t('EditCategory')}</h1>
          <div className=''>
            <div className='flex flex-col mb-3'>
              {t('ChangeNameCategory')}:
              <input type="text"
                value={categoryName}
                className='w-full bg-[var(--bg-input)] p-1 rounded-[5px] edit-reports-input'
                onChange={(e) => setCategoryName(e.target.value)} />
            </div>
            <div className='flex flex-col mb-4'>
              <div className='flex flex-row justify-between'>
                <div>{t('SelectSupervisor')}:</div>
                {categorySuper ? (
                  users
                    ?.filter((user: any) => user.id === categorySuper)
                    .map((user: User) => (
                      <div key={user.id} className='text-[#1976d2]'>
                        ({user.first_name} {user.last_name})
                      </div>
                    ))
                ) : (
                  <div>(none)</div>
                )}
              </div>
              <select
                className="edit-select p-[5px] rounded-[5px] edit-input"
                onChange={(e) => { setSelectedSupervisor(parseInt(e.target.value) || null) }}
                value={selectedSupervisor ? selectedSupervisor : ''}>
                {categorySuper ? (
                  users
                    ?.filter((user: any) => user.id === categorySuper)
                    .map((user: User) => (
                      <option key={user.id} value={user.id} className='text-[#1976d2]'>
                        ({user.first_name} {user.last_name})
                      </option>
                    ))
                ) : (
                  <option key='none' value="null">none</option>
                )}
                {users
                  ?.filter((user) => user.id !== categorySuper)
                  .sort((a, b) => {
                    if (a.first_name < b.first_name) return -1;
                    if (a.first_name > b.first_name) return 1;
                    return 0;
                  })
                  .map((user) => (
                    <option key={user.id} value={user.id} >
                      {user.first_name} {user.last_name}
                    </option>
                  ))}
                {categorySuper && <option key='none' value="null">none</option>}
              </select>
            </div>
            <div className='flex flex-col mb-4'>
              <div className='flex flex-row justify-between'>
                <div>{t('ChangeParents')}:</div>
              </div>
              <div className='w-full'>
                <div className="timesheets-category-box w-full bg-[var(--bg-input)] text-[var(--text-color)] relative text-[18px] rounded-[5px] py-[5px] px-[10px] flex justify-between items-center cursor-text" onClick={handleClickCategoryBox}>
                  <div className="timesheets-category-context width-fix">{categoryNameEdit ? categoryNameEdit : 'none'}</div>
                  <div className="timesheets-category-arrow"><FontAwesomeIcon icon={faChevronDown} className='text-[13px] cursor-pointer opacity-50' /></div>
                  {isCategoryListVisible && (
                    <div className="timesheets-category-list pb-0 w-full absolute top-[35px] right-0 z-[999] py-[5px]" onClick={handleCategoryListClick}>
                      <div className='fix-height mb-[10px]'>
                        <TreeViewEditModal
                          nodes={categoryEdit}
                          onNodeSelect={handleNodeSelect}
                          selectedCategoryId={selectedCategoryIdEdit} />
                      </div>
                      <div className="py-[5px] px-[20px] flex justify-end bg-fix">
                        <button className='bg-[var(--btn-user-btn)] text-[16px] text-[var(--text-color)] border-none py-[5px] px-[15px] cursor-pointer rounded-[5px] hover:bg-[var(--btn-user-hover)]'
                          onClick={() => handleClickAddCategoryId(Number(selectedCategoryIdEdit[0]))}>
                          select
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='flex flex-col mb-4'>
              <div className='flex flex-row justify-between'>
                <div>{t('SelectTasks')}:</div>
              </div>
              <SelectTaskEdit
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask}
                category={category} />
            </div>
          </div>
          <div className='flex justify-end items-center gap-[10px] mb-[25px]'>
            <Button
              variant="outlined"
              style={{
                fontSize: 12,
                padding: '2px 7px'
              }}
              onClick={handleCopyTasks}>
              {t('Copy Tasks')}</Button>
            <Button
              variant="outlined"
              style={{
                fontSize: 12,
                padding: '2px 7px'
              }}
              onClick={handlePasteTasks}
              className={isPasteDisabled ? 'disabled:opacity-50' : ''}
              disabled={isPasteDisabled}
            >
              {t('Paste Tasks')}</Button>
          </div>
          <div className='flex justify-between items-center gap-4 mt-3'>
            <Button
              variant="contained"
              sx={{
                padding: '5px 10px',
                fontSize: 14,
                width: '100%',
                color: 'white !important'
              }}
              onClick={() => updateCategory(selectedCatogoryId, categoryName, selectedSupervisor)}>
              {t('Change')}
            </Button>
            <Button
              variant="contained"
              sx={{
                padding: '5px 10px',
                fontSize: 14,
                width: '100%',
                color: 'white !important'
              }}
              onClick={() => handleClose()}>
              {t('Close')}
            </Button>
          </div>
        </div>
      </List>
    </Box >
  );
}

export default EditCategoryModal;