import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { t } from 'i18next';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 410,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface ShowProps {
  openShow: boolean,
  setOpenShow: any,
  locationRow: any,
  categoryRow: any,
  statusRow: any,
  importCSV: any
}

const ShowNewLocation: React.FC<ShowProps> = ({
  openShow,
  setOpenShow,
  locationRow,
  categoryRow,
  statusRow,
  importCSV }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleCloseShow = () => setOpenShow(false);

  const removeDuplicates = (array: any[]) => Array.from(new Set(array));
  const uniqueLocationRow = locationRow ? removeDuplicates(locationRow) : [];
  const uniqueCategoryRow = categoryRow ? removeDuplicates(categoryRow) : [];
  const uniqueStatusRow = statusRow ? removeDuplicates(statusRow) : [];

  const handleCreate = async () => {
    if (!uniqueLocationRow && !uniqueCategoryRow && !statusRow) {
      return
    }
    try {
      if (uniqueLocationRow && uniqueLocationRow.length > 0) {
        await axios.post('location/add/many', {
          name: uniqueLocationRow
        });
      }
      if (uniqueCategoryRow && uniqueCategoryRow.length > 0) {
        await axios.post('category-equipment/add/many', {
          name: uniqueCategoryRow
        });
      }
      if (uniqueStatusRow && uniqueStatusRow.length > 0) {
        await axios.post('status/add/many', {
          name: uniqueStatusRow
        });
      }
      enqueueSnackbar('create successfully', { variant: 'success' });
      handleCloseShow();
      importCSV(Number(1));
    } catch (error: any) {
      console.error('Error', error);
      enqueueSnackbar('Error', { variant: 'error' });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openShow}
        onClose={handleCloseShow}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openShow}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              {uniqueLocationRow && uniqueLocationRow.length > 0 &&
                <>
                  These <span
                    className='text-blue-300 underline'>{uniqueLocationRow.length}
                  </span> locations do not exist:
                  <div className='text-[var(--text-alert)] text-left overflow-y-auto'
                    style={{ maxHeight: '160px' }}>
                    {uniqueLocationRow?.map((item: any, index: number) => (
                      <div key={index}>'{item}'</div>
                    ))}
                  </div>
                </>}
              {uniqueCategoryRow && uniqueCategoryRow.length > 0 &&
                <>
                  These <span
                    className='text-blue-300 underline'>{uniqueCategoryRow.length}
                  </span> category do not exist:
                  <div className='text-[var(--text-alert)] text-left overflow-y-auto'
                    style={{ maxHeight: '160px' }}>
                    {uniqueCategoryRow?.map((item: any, index: number) => (
                      <div key={index}>'{item}'</div>
                    ))}
                  </div>
                </>}
              {uniqueStatusRow && uniqueStatusRow.length > 0 &&
                <>
                  These <span
                    className='text-blue-300 underline'>{uniqueStatusRow.length}
                  </span> status do not exist:
                  <div className='text-[var(--text-alert)] text-left overflow-y-auto'
                    style={{ maxHeight: '160px' }}>
                    {uniqueStatusRow?.map((item: any, index: number) => (
                      <div key={index}>'{item}'</div>
                    ))}
                  </div>
                </>}
              <div>Should we create them?</div>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className='flex justify-between items-center gap-4'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleCreate()}>
                {t('Create')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleCloseShow()}>
                {t('Cancel')}
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ShowNewLocation;