import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { RootState } from '../redux/store';
import { Dayjs } from 'dayjs';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import 'moment-timezone';
import ReportsTable from './timesheetsComp/ReportsTable';
import Calendar from './timesheetsComp/Calendar';
import { transformToHierarchy } from './util/tree';
import TreeViewComponent from './util/TreeViewComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import enGB from 'date-fns/locale/en-GB';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import SelectTask from './timesheetsComp/SelectTask';
import TablePagination from '@mui/material/TablePagination';
import Breadcrums from './util/Breadcrums';
import { useLocation } from "react-router-dom";
import AproveModal from './modal/AproveModal'
import { t } from 'i18next';
import Button from '@mui/material/Button';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import AddTempToTimetheets from './modal/AddTempToTimetheets';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Template from './Template';

registerLocale('en-GB', enGB);

interface Report {
  date: string;
  startTime: string;
  endTime: string;
  description: string;
  status: string;
}

interface TimeSheetsProps {
  access: any,
  is24HourFormat: boolean
}

interface Temp {
  temp_group_number: string;
  temp_name: string;
}

const Timesheets: React.FC<TimeSheetsProps> = ({ access, is24HourFormat }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [category, setCategory] = useState<any[]>([]);
  const [userInfo, setUserInfo] = useState('');
  const [reports, setReports] = useState([])
  const [startDate, setStartDate] = useState(new Date());
  const [timeFrom, setTimeFrom] = useState(moment().hour(9).minute(0).toDate());
  const [timeTo, setTimeTo] = useState(moment().hour(9).minute(0).toDate());
  const [categoryName, setCategoryName] = useState<any[]>([]);
  const [allCategory, setAllCategory] = useState<any[]>([]);
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();
  const [description, setDescription] = useState('')
  const [selectedCategoryId, setSelectedCategoryId] = useState<number[]>([]);
  const transformToHierarchyCallback = useCallback(transformToHierarchy, []);
  const [isCategoryListVisible, setCategoryListVisible] = useState(false);
  const [test, setTest] = useState<any>();
  const [week, setWeek] = useState<Dayjs[]>([]);
  const [dayPick, setDayPik] = useState<Dayjs | null>(null);;
  const [openCheckModal, setCheckOpenModal] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [perWeek, setPerWeek] = useState('');
  const [locationName, setLocationName] = useState('');
  const location = useLocation();
  const [toils, setToils] = useState();
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [showAprove, setShowAprove] = useState(false);
  const [buttonAdd, setButtonAdd] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedTemp, setSelectedTemp] = useState('');
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [allTemplateSelect, setAllTemplateSelect] = useState<Temp[]>([]);
  const [value, setValue] = useState('timesheets');
  let sum = 0;

  const transformedWeek = week.map((date: any) => moment(date.$d).format('DD.MM.YYYY'));

  const handleClick = () => {
    if (selectedIndex === -1) {
      return
    }
    setSelectedTemp(allTemplateSelect[selectedIndex].temp_group_number);
    setOpenAdd(true);
    // console.info(`You clicked ${allTemplateSelect[selectedIndex].temp_name}`);
    // console.info(`You clicked ${allTemplateSelect[selectedIndex].temp_group_number}`);
    // console.info(`You clicked ${moment(week[0].toDate()).startOf('isoWeek').format('YYYY-MM-DD')}`);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //show category
  const handleClickCategoryBox = (event: React.MouseEvent) => {
    setCategoryListVisible(!isCategoryListVisible);
    event.stopPropagation();
  };

  const handleCategoryListClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  //category select
  const handleNodeSelect = (nodeId: number) => {
    setSelectedCategoryId([nodeId])
    if (nodeId === -1) {
      setCategoryName(['none'])
      setCategoryListVisible(false);
    }
    const selectedCategory = allCategory?.find(node => node.id === Number(nodeId));
    if (selectedCategory) {
      const hasChildren = allCategory.some(node => node.parentId === Number(nodeId));
      if (hasChildren) {
        return true;
      }
      setCategoryName(selectedCategory.name)
      setTest(nodeId)
      handleTimeFromChange(timeFrom)
      setCategoryListVisible(false);
    } else {
      console.log('Category not found');
    }
  };

  //add date
  const handleDateChange = (date: Date) => {
    const newDate = moment(date).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
    setStartDate(newDate);
  };

  //add time from
  const handleTimeFromChange = (time: Date) => {
    setTimeFrom(time);
  };

  //add time to
  const handleTimeToChange = (time: Date) => {
    setTimeTo(time);
  };

  //add value to description
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDescription(value);
  };

  //add timesheets
  const handleAddClick = async () => {
    const endTimeDate = timeTo < timeFrom ? moment(startDate).add(1, 'day').format('YYYY-MM-DD') : moment(startDate).format('YYYY-MM-DD');

    // Check if timeTo is equal to 00:00:00, and consider it as 24:00
    const isMidnight = moment(timeTo).format('HH:mm:ss') === "00:00:00";

    if ((moment(timeFrom).format('HH:mm:ss') >= moment(timeTo).format('HH:mm:ss')) && !isMidnight) {
      enqueueSnackbar('Invalid time range: TimeFrom must be less than TimeTo', { variant: 'error' });
      return;
    }

    if (selectedCategoryId.length === 0) {
      enqueueSnackbar('Invalid category: You did not select a category', { variant: 'error' });
      return;
    }
    if (categoryName.length === 0) {
      enqueueSnackbar('Invalid category: You did not select a category', { variant: 'error' });
      return;
    }
    if (selectedTasks.length === 0) {
      enqueueSnackbar('Invalid task: You did not select a task', { variant: 'error' });
      return;
    }
    if (!week.some(day => dayjs(day).isSame(startDate, 'day'))) {
      enqueueSnackbar('Invalid date: The selected date does not belong to the current week', { variant: 'error' });
      return;
    }

    // Convert timeFrom and timeTo to moment objects
    const newStartTime = moment(startDate).set({
      hours: moment(timeFrom).hours(),
      minutes: moment(timeFrom).minutes(),
      seconds: moment(timeFrom).seconds()
    });

    // If the timeTo is 00:00:00, consider it as 24:00
    const newEndTime = isMidnight
      ? moment(startDate).add(1, 'day').startOf('day')
      : moment(startDate).set({
        hours: moment(timeTo).hours(),
        minutes: moment(timeTo).minutes(),
        seconds: moment(timeTo).seconds()
      });

    // Check for time overlap with existing reports
    const isTimeConflict = reports.some((report: any) => {
      const existingStartTime = moment(report.startTime);
      const existingEndTime = moment(report.endTime);

      // Check if newStartTime or newEndTime overlaps with existing report's time range
      return (
        moment(startDate).isSame(report.date, 'day') &&
        (
          (newStartTime.isBetween(existingStartTime, existingEndTime, null, '[)') ||
            newEndTime.isBetween(existingStartTime, existingEndTime, null, '(]')) ||
          (existingStartTime.isBetween(newStartTime, newEndTime, null, '[)') ||
            existingEndTime.isBetween(newStartTime, newEndTime, null, '(]'))
        )
      );
    });

    if (isTimeConflict) {
      enqueueSnackbar('Time conflict: The selected time is busy', { variant: 'error' });
      return;
    }

    let selectedTasksArray = [];
    if (selectedTasks) {
      selectedTasksArray = selectedTasks.map((option) => option.value);
    }
    setButtonAdd(true);
    try {
      await axios.post('/time-reports', {
        date: startDate,
        startTime: moment(startDate).format('YYYY-MM-DD') + ' ' + moment(timeFrom).format('HH:mm:ss'),
        endTime: endTimeDate + ' ' + moment(timeTo).format('HH:mm:ss'),
        categoryId: test,
        description: description,
        tasksId: selectedTasksArray
      });
      updateReports();
      setDescription('');
      enqueueSnackbar('Report created successfully', { variant: 'success' });
    } catch (error: any) {
      console.error("Error adding timesheet:", error);
      enqueueSnackbar("Error adding timesheet", { variant: 'error' });
    }
  };

  const handleChangeAll = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  //update reports function
  const updateReports = async () => {
    setLoading(true);
    try {
      const { data: updatedReports } = await axios.get('/time-reports');
      setReports(updatedReports);
    } catch (error) {
      console.error("Error updating reports:", error);
    } finally {
      setLoading(false);
      setButtonAdd(false);
    }
  };

  //sort reports
  const sortedReports = reports?.slice().sort((a, b) => {
    const dateA = moment((a as Report).date);
    const dateB = moment((b as Report).date);
    if (dateA.isSame(dateB, 'day')) {
      const timeA = moment((a as Report).startTime).utcOffset((a as Report).startTime);
      const timeB = moment((b as Report).startTime).utcOffset((b as Report).startTime);
      return timeA.isBefore(timeB) ? -1 : timeA.isAfter(timeB) ? 1 : 0;
    } else {
      return dateA.isBefore(dateB) ? -1 : 1;
    }
  });

  //create rows from reports
  const rows = sortedReports?.filter((item: any) => {
    const itemDate = moment(item.date).format('DD.MM.YYYY');
    return transformedWeek.includes(itemDate)
  }).map((item: any) => {
    const startTime = moment(item.startTime);
    const endTime = moment(item.endTime);
    let duration = moment.duration(endTime.diff(startTime));
    if (duration.asMinutes() < 0) {
      duration = moment.duration(endTime.add(1, 'day').diff(startTime));
    }
    const diffInMinutes = endTime.diff(startTime, 'minutes');
    const roundedMinutes = Math.ceil(diffInMinutes / 5) * 5;
    sum += roundedMinutes;
    return {
      date: item.date,
      startTime: item.startTime,
      endTime: item.endTime,
      categoryid: item.categoryId,
      description: item.description,
      dateId: item.id,
      status: item.status,
      tasks: item.tasks,
      supervisorId: item.supervisorId,
      supervisor: item.supervisor,
      categoryTree: `${item.categoryTree}`,
      supervisorFirstName: item.supervisor?.first_name,
      supervisorLastName: item.supervisor?.last_name,
      allTime: `${Math.floor(roundedMinutes / 60)}:${(roundedMinutes % 60).toFixed(0).padStart(2, '0')}`
    };
  });

  // Grouping rows by date
  const groupedRows: { [key: string]: any[] } = {};
  rows.forEach(row => {
    const date = moment(row.date).format('YYYY-MM-DD');
    if (!groupedRows[date]) {
      groupedRows[date] = [];
    }
    groupedRows[date].push(row);
  });

  // We are checking whether the reports for this week are not in "pending, rejected, fulfilled" status
  const isStartDateInCurrentWeek = () => {
    const nonPendingReports = rows.some(report => {
      return dayjs(report.date) && (
        report.status === 'pending'
        || report.status === 'rejected'
        || report.status === 'fulfilled');
    });

    return !nonPendingReports;
  };

  //chacge reports status to peding
  const changeReportStatus = async (reportIds: any) => {
    try {
      await axios.put('/time-reports/updateStatus', { reportsId: reportIds, status: 'pending' });
    } catch (error) {
      console.error(`Error changing status of reports:`, error);
      enqueueSnackbar(`Error changing status of reports`, { variant: 'error' });
    }
  };

  //sent to approve
  const sendToApprove = async () => {
    setLoading(true);
    setShowAprove(false);
    try {
      const reportIdsToChange = rows
        .filter(report => report.status !== 'fulfilled' && report.status !== 'pending')
        .map((report: any) => report.dateId);
      if (reportIdsToChange.length > 0) {
        await changeReportStatus(reportIdsToChange);
      }
      const { data } = await axios.get('user');
      if (data) {
        setToils(data.totalToils);
      }
      updateReports();
      enqueueSnackbar('Reports successfully sent for review', { variant: 'success' });
    } catch (error) {
      console.error('Error sending reports for review:', error);
      enqueueSnackbar('Error sending reports for review', { variant: 'error' });
    }
  };

  //get all data and check user
  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          setToils(data.totalToils);
        }
        const { data: categoryData } = await axios.get('category');
        const { data: reportsData } = await axios.get('/time-reports');
        const { data: template } = await axios.get('template/user/group');
        setAllTemplateSelect(template);
        setPerWeek(data.hoursPerWeek)
        setReports(reportsData);
        setAllCategory(categoryData);
        const hierarchyData = transformToHierarchyCallback(categoryData);
        setCategory(hierarchyData);
        setLocationName(location.pathname);
        setUserInfo(`Hi ${data.first_name}`);
        dispatch(setAuth(true));
      } catch (e) {
        setUserInfo('You are not authenticated');
        dispatch(setAuth(false));
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch, transformToHierarchyCallback, location.pathname]);

  useEffect(() => {
    const handleDocumentClick = () => {
      setCategoryListVisible(false);
    };
    if (isCategoryListVisible) {
      document.addEventListener('click', handleDocumentClick);
    }
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isCategoryListVisible]);

  const convertToHoursAndMinutes = (totalToils: any) => {
    const isNegative = totalToils < 0;
    const absoluteTotalToils = Math.abs(totalToils);
    const hours = Math.trunc(absoluteTotalToils);
    const minutes = Math.round((absoluteTotalToils - hours) * 60);
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${isNegative ? '-' : ''}${hours}:${formattedMinutes}`;
  };

  useEffect(() => {
    if (dayPick) {
      const selectedDay = dayPick.toDate();
      const newDate = moment(selectedDay).set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
      setStartDate(newDate)
    }
    // if (week.length > 0) {
    //   const currentWeekStart = moment().startOf('isoWeek').toDate();
    //   const isCurrentWeek = week.some(item => moment(item.toDate()).isSame(currentWeekStart, 'isoWeek'));
    //   if (isCurrentWeek) {
    //     setStartDate(new Date());
    //   } else {
    //     const firstDayOfWeek = moment(week[0].toDate()).startOf('isoWeek').toDate();
    //     setStartDate(firstDayOfWeek);
    //   }
    // }
  }, [week, userInfo, dayPick]);

  const fixPerWeek = Number(perWeek) * 60;

  return (
    <>
      <Breadcrums locationName={locationName} />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{
            borderBottom: 1,
            borderColor: "rgba(var(--shodow), 0.05)"
          }}>
            <TabList onChange={handleChangeAll}>
              <Tab label="Timesheets" value='timesheets' sx={{ color: 'var(--text-color)', fontSize: 12 }} />
              <Tab label="Templates" value='templates' sx={{ color: 'var(--text-color)', fontSize: 12 }} />
            </TabList>
          </Box>
          <TabPanel
            value='timesheets'
            sx={{ padding: '10px 0' }}>
            {auth && access.timesheets_get > 0 ? (
              <div>
                <div className="bg-[var(--bg-form)] w-full min-w-[500px] flex justify-left items-center gap-[5px] p-[15px] mb-[15px] rounded-[5px] flex-wrap">
                  <div className='flex gap-[5px]'>
                    <div className="flex max-w-[140px]">
                      <DatePicker
                        selected={startDate}
                        onChange={(date: any) => handleDateChange(date)}
                        className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="bottom-start"
                        locale="en-GB"
                        minDate={week.length > 0 ? week[0].toDate() : null}
                        maxDate={week.length > 0 ? week[week.length - 1].toDate() : null}
                      />
                    </div>
                    <div className='flex max-w-[120px]'>
                      <DatePicker
                        selected={timeFrom}
                        onChange={(time: any) => handleTimeFromChange(time)}
                        className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                        placeholderText="Select Time"
                        showTimeSelect
                        dateFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                        timeFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                        showTimeSelectOnly
                        timeIntervals={15}
                      />
                    </div>
                    <div className='flex max-w-[120px]'>
                      <DatePicker
                        selected={timeTo}
                        onChange={(time: any) => handleTimeToChange(time)}
                        className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                        showTimeSelect
                        dateFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                        timeFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                        showTimeSelectOnly
                        timeIntervals={15}
                      />
                    </div>
                  </div>
                  <div className="timesheets-category-box bg-[var(--bg-input)] text-[var(--text-color)] relative w-[180px] flex-grow text-[16px] rounded-[5px] py-[5px] px-[10px] flex justify-between items-center cursor-text"
                    onClick={handleClickCategoryBox}>
                    <div className="width-fix">{categoryName.length ? categoryName : <div className='text-[#80807a] text-[16px]'>{t('SelectCategory')}</div>}</div>
                    <div className="timesheets-category-arrow"><FontAwesomeIcon icon={faChevronDown} className='text-[13px] cursor-pointer opacity-50 ' /></div>
                    {isCategoryListVisible && (
                      <div className="timesheets-category-list fix-height w-full absolute top-[45px] right-0 z-[999] py-[5px]"
                        onClick={handleCategoryListClick}>
                        <TreeViewComponent
                          nodes={category}
                          onNodeSelect={handleNodeSelect}
                          updateTree={''}
                          openCheckModal={openCheckModal}
                          selectedCategoryId={''} />
                      </div>
                    )}
                  </div>
                  <div
                    className='w-[300px] relative flex-grow'>
                    <SelectTask
                      test={test}
                      setSelectedTasks={setSelectedTasks}
                      selectedTasks={selectedTasks} />
                  </div>
                  <div className='flex gap-[10px] items-center flex-grow w-[350px]'>
                    <div className='w-full'>
                      <input
                        type="text"
                        className='w-full input-timesheet text-[var(--text-color)] bg-[var(--bg-input)] p-[5px] rounded-[5px]'
                        value={description}
                        placeholder={t('DescriptionPoins')}
                        onChange={handleDescriptionChange} />
                    </div>
                    <Button
                      variant="contained"
                      endIcon={<PlaylistAddIcon
                        sx={{ color: 'white !important' }} />}
                      sx={{
                        padding: '5px 15px',
                        fontSize: 14,
                        color: 'white !important'
                      }}
                      onClick={handleAddClick}
                      disabled={!isStartDateInCurrentWeek() || buttonAdd}>
                      {t('Add')}
                    </Button>
                  </div>
                </div>
                <div className='flex gap-[15px] media-flex-wrap'>
                  <div className='relative box-users flex flex-col justify-start p-[15px] rounded-[5px] pb-[50px] min-w-[500px] flex-grow'>
                    {rows.length > 0 || !loading ? (
                      <ReportsTable
                        rows={rows}
                        updateReports={updateReports}
                        openCheckModal={openCheckModal}
                        setCheckOpenModal={setCheckOpenModal}
                        page={page}
                        rowsPerPage={rowsPerPage} />) : (
                      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                      </Box>
                    )}
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 50]}
                      component="div"
                      className='absolute bottom-[5px] left-[0]'
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <div className='absolute bottom-[10px] right-[18px] flex gap-[10px]'>
                      <React.Fragment>
                        <ButtonGroup
                          variant="contained"
                          ref={anchorRef}
                          sx={{
                            height: '30px',
                            minWidth: '195px',
                            color: 'white !important'
                          }}
                          aria-label="Button group with a nested menu">
                          <Button
                            sx={{
                              width: '100%',
                              color: 'white !important'
                            }}
                            onClick={handleClick}>
                            {allTemplateSelect[selectedIndex] ? allTemplateSelect[selectedIndex].temp_name : 'select Template'}
                          </Button>
                          <Button
                            size="small"
                            aria-label="select merge strategy"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="menu"
                            sx={{
                              color: 'white !important'
                            }}
                            onClick={handleToggle}>
                            <ArrowDropDownIcon
                              sx={{
                                color: 'white !important'
                              }} />
                          </Button>
                        </ButtonGroup>
                        <Popper
                          sx={{ zIndex: 1 }}
                          open={open}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === 'bottom' ? 'center top' : 'center bottom',
                              }}
                            >
                              <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                  <MenuList id="split-button-menu" autoFocusItem>
                                    {allTemplateSelect?.map((option, index) => (
                                      <MenuItem
                                        key={option.temp_group_number}
                                        selected={index === selectedIndex}
                                        value={option.temp_group_number}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                      >
                                        {option.temp_name}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </React.Fragment>
                      <Button
                        variant="contained"
                        endIcon={<NoteAddIcon
                          sx={{ color: 'white !important' }} />}
                        sx={{
                          height: '30px',
                          fontSize: 14,
                          color: 'white !important'
                        }}
                        onClick={() => !loading && setShowAprove(true)}
                        disabled={loading}>
                        {loading ? (
                          <CircularProgress size={15} color="inherit" />
                        ) : (
                          t('SendTo')
                        )}
                      </Button>
                    </div>
                  </div>
                  <div className='flex flex-col gap-[15px] flex-grow media-calendar'>
                    <div className="timesheet-calendar rounded-[5px] p-[10px] max-h-[350px] flex-grow">
                      <Calendar
                        setWeek={setWeek}
                        setDayPik={setDayPik} />
                    </div>
                    <div className="timesheet-box-right-bottom py-[15px] px-[25px] flex justify-between max-h-[150px] media-ter">
                      <div className="timesheet-box-right-bottom-block">
                        <h5>{t('PerWee')}</h5>
                        <h1>{Math.floor(fixPerWeek / 60)}:{(fixPerWeek % 60).toFixed(0).padStart(2, '0')}hrs</h1>
                        {/* <p className='text-[14px]'>of 4 hrs weekly limit</p> */}
                      </div>
                      <div className="timesheet-box-right-bottom-block">
                        <h5>{t('TOIL')}</h5>
                        <h1>{convertToHoursAndMinutes(toils)}hrs</h1>
                        {/* <p className='text-[14px]'>not approve {convertToHoursAndMinutes(pendingToils)}hrs</p> */}
                      </div>
                      <div className="timesheet-box-right-bottom-block">
                        <h5>{t('ThisWeek')}</h5>
                        <h1>{Math.floor(sum / 60)}:{(sum % 60).toFixed(0).padStart(2, '0')}hrs</h1>
                        {/* <p className='text-[14px]'>of 4 hrs weekly limit</p> */}
                      </div>
                    </div>
                  </div>
                </div>
                {showAprove &&
                  <AproveModal
                    showAprove={showAprove}
                    setShowAprove={setShowAprove}
                    sendToApprove={sendToApprove} />}
                {openAdd &&
                  <AddTempToTimetheets
                    openAdd={openAdd}
                    setOpenAdd={setOpenAdd}
                    week={week}
                    updateReports={updateReports}
                    selectedTemp={selectedTemp}
                    setSelectedIndex={setSelectedIndex} />}
              </div >
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
              </Box>
            )}
          </TabPanel>
          <TabPanel
            value='templates'
            sx={{ padding: '10px 0' }}>
            <Template
              access={access}
              is24HourFormat={is24HourFormat} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default Timesheets;