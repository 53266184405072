import React, { useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import moment from 'moment';
import Collapse from '@mui/material/Collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { t } from 'i18next';
import Alert from '@mui/material/Alert';

interface TemporaryDrawerProps {
  userNotice: any;
  refreshUserHeder: any;
}

export default function TemporaryDrawer({ userNotice, refreshUserHeder }: TemporaryDrawerProps) {
  const [open, setOpen] = useState<{ [key: number]: boolean }>(
    userNotice.reduce((acc: any, notice: any) => {
      acc[notice.id] = true;
      return acc;
    }, {} as { [key: number]: boolean })
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = async (noticeId: number) => {
    try {
      setOpen((prevOpen) => ({
        ...prevOpen,
        [noticeId]: false,
      }));
      await axios.delete(`notice/${noticeId}`);
      enqueueSnackbar('Notice deleted successfully', { variant: 'success' });
      setTimeout(refreshUserHeder, 500);
    } catch (error) {
      console.error('Error deleting notice:', error);
      enqueueSnackbar('Error deleting notice', { variant: 'error' });
    }
  };

  const formatSource = (source: string) => {
    const categoryIndex = source.indexOf('category:');
    if (categoryIndex !== -1) {
      const beforeCategory = source.substring(0, categoryIndex).trim();
      const category = source.substring(categoryIndex).trim();
      return (
        <div>
          <span>{beforeCategory}</span><br />
          <span>{category}</span>
        </div>
      );
    }
    return source;
  };

  const getColorBySeverity = (severity: string) => {
    switch (severity) {
      case 'warning':
        return '#ed6c02 !important'; 
      case 'success':
        return '#4caf50 !important'; 
      case 'error':
        return '#f44336 !important'; 
      default:
        return '#9e9e9e !important'; 
    }
  };

  return (
    <Box
      sx={{
        width: 350,
        height: '100%',
        background: 'var(--bg-form)',
      }}
      role="presentation"
    >
      <List>
        <div className="text-[var(--text-color)] m-[10px]">
          <h1 className='text-[20px] text-right font-bold mb-[10px]'>{t('Notification')}</h1>
          {userNotice.length ? (
            userNotice.map((notice: any) => (
              <Collapse in={open[notice.id]} key={notice.id}>
                <Alert
                  severity={notice?.severity || 'warning'}
                  className="flex w-full mb-[7px] text-[14px] timesheet-edit-input rounded-[5px] bg-red"
                     sx={{
                    '& .MuiSvgIcon-root': {
                      color: getColorBySeverity(notice?.severity), 
                    },
                  }}>
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center w-full">
                      <div className="text-[12px] text-[var(--text-alert)]">
                        {moment.utc(notice.createdAt).format('HH:mm DD.MM.YYYY')}
                      </div>
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="text-[16px] rounded-[50px] px-[5px] py-[3px] cursor-pointer text-[var(--text-alert)] hover:bg-[var(--btn-user-in)]"
                        onClick={() => handleClose(notice.id)}
                      />
                    </div>
                    <div className="text-[11px] text-[var(--text-alert)]">{formatSource(notice.source)}</div>
                    <div className='text-[var(--text-alert)]'>message: {notice.message}</div>
                  </div>
                </Alert>
              </Collapse>
            ))) : (
            <div
              className='p-[10px] text-center'>
              No notices to display
            </div>
          )}
        </div>
      </List>
    </Box>
  );
}
