export const ua = {
  // Nav 
  "Timesheets": "Табели",
  "Reports": "Звіти",
  "Approve": "Схвалити",
  "Clients": "Клієнти",
  "Equipment": "Обладнання",
  "Booking": "Бронювання",
  "Expenses": "Витрати",
  "Orders": "Замовлення",
  "Admin": "Адміністрування",
  "Users": "Користувачі",
  "Toil": "Відпочинок",
  "UsersReports": "Звіти-користувачі",
  // Header menu 
  "Setting": "Налаштування",
  "Profile": "Профіль",
  "LogOut": "Вийти",
  "Login": "Увійти",
  "Search": "Пошук...",
  "Notification": "Сповіщення",
  // Report table 
  "Date": "Дата",
  "StartTime": "Початок",
  "EndTime": "Кінець",
  "Category": "Категорія",
  "Task": "Завдання",
  "Description": "Опис",
  "Supervisor": "Супервізор",
  "Hours": "Години",
  "Edit": "Редагувати",
  "Download": "Завантажити",
  "AreYouSureDownload": "Ви впевнені, що хочете завантажити цей звіт?",
  // Aprove Table 
  "TotalUserHours": "Загальна кількість годин користувача",
  "TotalCategoryHours": "Загальна кількість годин за категорією",
  "Name": "Ім'я",
  "Total": "Всього",
  "ApproveAll": "Підтвердити всі",
  "Return": "Повернути",
  "ApproveAllForWeek": "Підтвердити тиждень",
  "TotalWeek": "Всього за тиждень",
  "RemoveAllForWeek": "Вернути тиждень",
  "AreYouSureBack": "Ви дійсно хочете вернути весь тиждень?",
  "RejectMessage": "Введіть причину відхилення",
  // Timesheets 
  "AreYouSureReport": "Ви впевнені, що хочете надіслати щотижневий звіт?",
  "Add": "Додати",
  "SendTo": "Надіслати на схвалення",
  "PerWee": "За тиждень",
  "TOIL": "TOIL",
  "ThisWeek": "Цей тиждень",
  "SelectCategory": "Оберіть категорію...",
  "DescriptionPoins": "Опис...",
  // Equipment
  "Import": "Імпортувати",
  "AreYouSureEquipment": "Ви впевнені, що хочете завантижити цей зразок?",
  "AreYouSureEquipmentAll": "Ви впевнені, що хочете завантижити вибране обладнання?",
  "AddEqp": "Додати Обладнання",
  "EqpList": "Список обладнання",
  "Number": "Номер",
  "Item": "Предмет",
  "Manufacturer": "Виробник",
  "Model": "Модель",
  "SerialNumber": "Серійний номер",
  "Responsive": "Відповідальний",
  "PurchaseDate": "Дата придбання",
  "Cost": "Вартість",
  "PATTestDate": "Дата PAT-тесту",
  "PATTestDue": "Термін PAT-тесту",
  "PATTestUser": "Користувач PAT-тесту",
  "Note": "Примітка",
  "ResponsibleForItem": "Відповідальний за предмет:",
  "WarrantyDue": "Гарантія до",
  "ItemInfo": "Інформація про предмет",
  "PATInfo": "Інформація про PAT",
  "ResponsibleForPAT": "Відповідальний за PAT",
  "EditEquipment": "Редагувати обладнання",
  "Eqp": "Обладнання",
  "RentalOpportunity": "Можливість оренди",
  "InventoryID": "Ідентифікатор інвентарю",
  "EditEqp": "Редагувати обладнання",
  "InventoryDate": "Дата інвентаризації",
  "InventoryUser": "Користувач інвентаризації",
  "InventoryInfo": "Інформація про інвентаризацію",
  "SelectTheNumberOfMonths": "Виберіть кількість місяців",
  "Selected": "вибрано",
  //Template
  "Template": "Шаблон",
  "Temp": "Шаблона",
  "CreateNew": "Створити Новий",
  "MyTemplates": "Мої Шаблони",
  "EditTemp": "Редагувати Шаблон",
  "To": "до",
  "Empty": "Пусто",
  "InsertTemp":"Вставити цей шаблон в репорт?",
  //Booking
  "BookingCalendar": "Календар бронювань",
  "NoBookingsAvailable": "Немає бронювань",
  // Reports 
  "SortBy": "Сортувати за",
  "User": "Користувач",
  "StatusReport": "Статус",
  "Export": "Експортувати",
  // Users 
  "Email": "Електронна пошта",
  "Role": "Роль",
  "users": "користувачі",
  "UsersList": "Список користувачів",
  "AddUser": "Додати користувача",
  "fillter": "фільтр...",
  "EditUser": "Редагувати користувача",
  "PersonalInformation": "Особиста інформація",
  "LastName": "Прізвище",
  "SecondName": "По батькові",
  "DateOfBirth": "Дата народження",
  "Sex": "Стать",
  "WorkInformation": "Робоча інформація",
  "JobTitle": "Посада",
  "ContractStartDate": "Дата початку контракту",
  "Department": "Відділ",
  "ContractType": "Тип контракту",
  "ContractedHoursPerWeek": "Кількість годин на тиждень за контрактом",
  "MinLeaveEntitlement": "Мін. відпустка",
  "ContactInformation": "Контактна інформація",
  "MobilePhone": "Мобільний телефон",
  "WorkPhone": "Робочий телефон",
  "Postcode": "Індекс",
  "City": "Місто",
  "Address": "Адреса",
  "OtherSettings": "Інші налаштування",
  "TimeZone": "Часовий пояс",
  "NotificationLanguage": "Мова сповіщень",
  "ChangePassword": "Змінити пароль",
  "RegisterUser": "Зареєструвати користувача",
  "RegistrationInformation": "Інформація про реєстрацію",
  "Password": "Пароль",
  "ConfirmPassword": "Підтвердити пароль",
  "FirstName": "Ім'я",
  "AreYouUser": "Ви впевнені, що хочете видалити цього користувача?",
  // Settings 
  "CompanyInfo": "Інформація про компанію",
  "CompanyName": "Назва компанії",
  "EditPanel": "Панель редагування",
  "Settings": 'Налаштування',
  "AddCategory": "Додати категорію",
  "AddChildCategoryFor": "Додати дочірню категорію для",
  "SelectSupervisor": "Вибрати керівника",
  "AddTasks": "Додати завдання",
  "AddCont": "Додати контракт",
  "AddDep": "Додати департамент",
  "AddParentCategory": "Додати батьківську категорію",
  "ChangeNameCategory": "Змінити назву категорії",
  "ChangeParents": "Змінити батьків",
  "AreYouSure": "Ви впевнені, що хочете видалити",
  "Sourc": "це джерело",
  "Sour": "джерела",
  "LegalEntityType": "тип юридичної особи",
  "LegalEntity": "юридичної особи",
  "LegalEntityFix": "юридичну особу",
  "Cat": "цю категорію",
  "Cont": "цей контракт",
  "Dep": "цей департамент",
  "Loc": "цю локацію",
  "Stat": "статус",
  "Equ": "цю категорію",
  "Tasks": "це завдання",
  "ChangeName": "Змінити назву",
  "AddRole": "Додати роль",
  "EnterNameOf": "Введіть назву",
  "EditRole": "Редагувати роль",
  "RoleName": "Назва ролі",
  "ColorRole": "Колір ролі",
  "CreateRole": "Створити Роль",
  "RoleAccess": "Доступ до ролі",
  "Delete": "Видалити",
  "ShowAll": "Пок. всі",
  "AddEdit": "Додати/Редагувати",
  "AreYouSureRole": "Ви впевнені, що хочете видалити цю роль?",
  "Create": "Створити",
  "Location": "Локацію",
  "Status": "статусу",
  "CatEqu": "Категорію Обладнання",
  "CatEqupm": "Категорії Обладнання",
  "Contract": "Контракт",
  "TasksType": "Завдання",
  "Roles": "Ролі",
  "Availability": "Доступність",
  "CreateEdit": "Ств/Ред",
  "EnterNameOfRole": "Введіть назву ролі",
  // TOIL
  "TotalTOIL": "Загальний TOIL",
  "AddToils": "Додати відпустку за робочий час",
  "AddInformation": "Додати інформацію",
  "CreatingDate": "Дата створення",
  "TotalHours": "Загальна кількість годин",
  "OursPerWeek": "Годин на тиждень",
  "Source": "Джерело",
  "removeTOIL": "Видалити TOIL",
  "discriptions": "Опис",
  "Refresh": "Оновити",
  // Tasks 
  "AllTasks": "Усі завдання",
  "SelectTasksPoins": "Вибрати завдання..",
  // Уdit Reports 
  "EditReports": "Редагувати звіти",
  "EditDate": "Редагувати дату",
  "EditCategory": "Редагувати категорію",
  "SelectTasks": "Вибрати завдання",
  "EditDescription": "Редагувати опис",
  // Delete modal 
  "AreYouReport": "Ви впевнені, що хочете видалити цей звіт?",
  "AreYouReportCat": "Ви впевнені, що хочете видалити категорію",
  "AreYouReportCatModal": "Ви впевнені, що хочете видалити категорію",
  // All button 
  "Yes": "Так",
  "No": "Hi",
  "Cancel": "Скасувати",
  "Change": "Змінити",
  "Register": "Реєстрація",
  "Close": "Закрити",
  // Other 
  "underDevelopment": "У розробці",
  "січень": "Січень",
  "лютий": "Лютий",
  "березень": "Березень",
  "квітень": "Квітень",
  "травень": "Травень",
  "червень": "Червень",
  "липень": "Липень",
  "серпень": "Серпень",
  "вересень": "Вересень",
  "жовтень": "Жовтень",
  "листопад": "Листопад",
  "грудень": "Грудень",
  "Monday": "Понеділок",
  "Tuesday": "Вівторок",
  "Wednesday": "Середа",
  "Thursday": "Четвер",
  "Friday": "П'ятниця",
  "Saturday": "Субота",
  "Sunday": "Неділя",
}