import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 410,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface ShowProps {
  openShow: boolean,
  setOpenShow: any,
  errorRow: any,
  importCSV: any
}

const ShowToDo: React.FC<ShowProps> = ({
  openShow,
  setOpenShow,
  errorRow,
  importCSV }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleCloseShow = () => setOpenShow(false);

  // const getErrorCount = (errors: any[]) => {
  //   const errorCount: { [key: string]: number } = {};
  //   errors.forEach((error) => {
  //     const errorField = error.field;
  //     if (errorCount[errorField]) {
  //       errorCount[errorField] += 1;
  //     } else {
  //       errorCount[errorField] = 1;
  //     }
  //   });
  //   return errorCount;
  // };

  // const errorCount = getErrorCount(errorRow);

  const handleToDo = async () => {
    try {
      handleCloseShow();
      importCSV(2, 2);
    } catch (error: any) {
      console.error('Error', error);
      enqueueSnackbar('Error', { variant: 'error' });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openShow}
        onClose={handleCloseShow}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openShow}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-left'>
              There are still errors in the fields <span
                className='text-blue-300 underline'>{errorRow.length}
              </span>:
              <div
                className='overflow-y-auto'
                style={{ maxHeight: '300px' }}>
                {errorRow.map((error: any, index: number) => (
                  <Typography key={index} sx={{ mt: 1 }} className='text-left text-[var(--text-alert)]'>
                    {`Field: ${error.field}, Error in: ${error.error.name} (${error.error.model})`}
                  </Typography>
                ))}
              </div>
              They will not be saved. Do you still want to continue?
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className='flex justify-between items-center gap-4'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleToDo()}>
                {t('Ok')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleCloseShow()}>
                {t('Cancel')}
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ShowToDo;
