import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface AddModalProps {
  openAdd: boolean,
  setOpenAdd: any,
  selectedTemplate: string,
  setCheckOpenModal: any,
  reports: any;
  setReports: any;
  selectedTemplateName: any;
  setSelectedTemplate: any;
  children?: React.ReactNode;
}


const AddTemplate: React.FC<AddModalProps> = ({
  openAdd,
  setOpenAdd,
  reports,
  setReports,
  setCheckOpenModal,
  selectedTemplate,
  setSelectedTemplate,
  selectedTemplateName }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const handleClose = () => {
    setOpenAdd(false);
    setCheckOpenModal(false);
  };

  useEffect(() => {
    if (selectedTemplate && selectedTemplateName) {
      setName(selectedTemplateName);
    } else {
      setName('');
    }
  }, [selectedTemplate, selectedTemplateName]);

  const handleAddTask = async () => {
    try {
      if (!selectedTemplate) {
        if (!name || !name.trim()) {
          enqueueSnackbar('Add name', { variant: 'error' });
          return
        }
      }
      const templateData = {
        temp_group_number: Number(selectedTemplate),
        temp_name: name,
        tmpArray: reports.map((report: any) => ({
          day_number: Number(report.selectedDay),
          endDay_number: Number(report.selectedDay),
          startTime: report.startTime,
          endTime: report.endTime,
          description: report.description,
          taskId: report.tasksId ? report.tasksId.map((task: any) => task.value).join(',') : '',
          categoryId: Number(report.categoryId),
          comments: ''
        })),
      };
      if (templateData) {
        await axios.post('template/add', templateData);
        setName('');
        setSelectedTemplate('');
        setReports([]);
        handleClose();
        enqueueSnackbar(`Add ${name}`, { variant: 'success' });
      }
    } catch (error: any) {
      console.error('Error', error);
      enqueueSnackbar('Error', { variant: 'error' });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openAdd}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAdd}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              <div className='mb-[10px] text-[18px]'>{`${t('Add')} ${selectedTemplate !== '' ? `${t('To')} "${selectedTemplateName}"` : t('Template')}?`}</div>
              {selectedTemplate === '' &&
                <div>
                  <input
                    className='w-full text-[16px] place-hold p-[5px] pl-[8px] rounded-[5px] bg-[var(--bg-input)] text-[var(--text-color)] timesheet-edit-input'
                    type="text"
                    placeholder={`${t('EnterNameOf')} ${t('Temp')}`}
                    value={name}
                    maxLength={12} 
                    onChange={(e) => setName(e.target.value)} />
                    <p
                    className='text-[8px] text-left opacity-40 pl-[5px]'>мaximum 12 characters.</p>
                </div>
              }
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className='flex justify-between items-center gap-4'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 20px',
                  fontSize: 14,
                  color: 'white !important',
                  width: '100%'
                }}
                onClick={() => handleAddTask()}>
                {t('Add')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 20px',
                  fontSize: 14,
                  color: 'white !important',
                  width: '100%'
                }}
                onClick={() => handleClose()}>
                {t('Cancel')}
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default AddTemplate;