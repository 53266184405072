import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface DeleteModalProps {
  open: boolean,
  setOpen: any,
  handleDeleteReport: any,
  selectedDateId: any,
  updateReports: any;
}


const DeleteModal: React.FC<DeleteModalProps> = ({ open, setOpen, handleDeleteReport, selectedDateId, updateReports }) => {
  const handleClose = () => setOpen(false);
  const handleDeleteAndClose = async () => {
    await handleDeleteReport(selectedDateId);
    updateReports();
    handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              {t('AreYouReport')}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className='flex justify-between items-center gap-4'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 15px',
                  fontSize: 14,
                  color: 'white !important',
                  width: '100%'
                }}
                onClick={() => handleDeleteAndClose()}>
                {t('Yes')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 15px',
                  fontSize: 14,
                  color: 'white !important',
                  width: '100%'
                }}
                onClick={() => handleClose()}>
                {t('Cancel')}
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default DeleteModal;