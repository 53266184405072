import React, { SyntheticEvent, useState, useEffect } from 'react'
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../../redux/authSlice';
import { RootState } from '../../redux/store';

const Register = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [userInfo, setUserInfo] = useState(0);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const auth = useSelector((state: RootState) => state.auth.value);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data } = await axios.get('user');
        if (data) {
          dispatch(setAuth(true));
          setUserInfo(data.roleId);
        }
      } catch (e) {
        dispatch(setAuth(false));
      }
    };
    checkAuth();
  }, [dispatch, userInfo]);

  const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!firstName || !lastName || !email || !password || !passwordConfirm) {
      setError('All fields are required');
      return;
    }
    if (!EMAIL_REGEX.test(email)) {
      setError('Invalid email format');
      return;
    }
    try {
      await axios.post('register', {
        first_name: firstName,
        last_name: lastName,
        email,
        password,
        password_confirm: passwordConfirm,
        companyid: company
      })
      setRedirect(true)
    } catch (e) {
      setError('Registration failed. Please try again.');
    }
  }

  if (redirect) {
    if (auth && userInfo === 4) {
      return <Navigate to={'/admin'} />;
    } else if (auth) {
      return <Navigate to={'/'} />;
    }
    return <Navigate to={'/login'} />;
  }

  return (
    (!auth ? (
      <div className='max-w-[480px] m-auto flex flex-col justify-start p-[40px] rounded-[5px] form-box'>
        {error && <p className="relative px-5 py-3 mb-4 text-center rounded text-red-900 bg-red-200 border-gray-200">{error}</p>}
        <h1 className='mt-[20px] text-[26px]'>Register {auth && 'user'}</h1>
        <form onSubmit={submit} className='flex flex-col justify-evenly flex-grow pb-4'>
          <input placeholder="First Name" type="text" id="first-name" onChange={e => setFirstName(e.target.value)} />
          <input placeholder="Last Name" type="text" id="last-name" onChange={e => setLastName(e.target.value)} />
          <input placeholder="Email" type="text" id="email" onChange={e => setEmail(e.target.value)} />
          <input placeholder="Company" type="text" id="register" onChange={e => setCompany(e.target.value)} />
          <input placeholder="Password" type="password" id="password" onChange={e => setPassword(e.target.value)} />
          <input placeholder="Confirm Password" type="password" id="confirm_pwd" onChange={e => setPasswordConfirm(e.target.value)} />
          <button className="bg-[#1976d2] text-white cursor-pointer rounded-[5px] button-border p-[10px] mx-auto mt-[20px] w-[70%] hover:bg-[#176bbe]" type='submit'>Sign Up</button>
        </form>
        <p className='text-[18px] text-center'>Already registered? <span className="inline-block"><Link className="text-[#1976d2]" to="/login">Sign In</Link></span></p>
      </div>
    ) : <Navigate to='/'/>
    ))
}

export default Register