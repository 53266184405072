import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import TreeViewComponent from '../util/TreeViewComponent';
import { transformToHierarchy } from '../util/tree';
import { useSnackbar } from 'notistack';
import { useLocation } from "react-router-dom";
import moment from 'moment';
import TaskEdit from '../timesheetsComp/TaskEdit';
import { t } from 'i18next';
import Button from '@mui/material/Button';

interface EditModalProps {
  setOpenEdit: any;
  selectedDateId: any;
  selectedDescription: any;
  selectedDate: Date;
  selectedDateFrom: any;
  selectedDateTo: any;
  updateReports: any;
  setCheckOpenModal: any;
  openCheckModal: boolean;
}

export default function EditReports({
  setOpenEdit,
  selectedDateId,
  selectedDescription,
  selectedDate,
  selectedDateFrom,
  selectedDateTo,
  updateReports,
  setCheckOpenModal,
  openCheckModal }: EditModalProps) {

  const [category, setCategory] = React.useState<any[]>([]);
  const [reportID, setReportId] = React.useState<any[]>([]);
  const [allCategory, setAllCategory] = React.useState<any[]>([]);
  const [categoryName, setCategoryName] = React.useState<any[]>([]);
  const [isCategoryListVisible, setCategoryListVisible] = React.useState(false);
  const [editedDescription, setEditedDescription] = React.useState<string>('');
  const [date, setDate] = React.useState<Date | null>(new Date(selectedDate));
  const [timeFrom, setTimeFrom] = React.useState(new Date());
  const [timeTo, setTimeTo] = React.useState(new Date());
  const [selectedTask, setSelectedTask] = React.useState<any[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = React.useState<number[]>([]);
  const transformToHierarchyCallback = React.useCallback(transformToHierarchy, []);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const handleClose = () => {
    setCategoryListVisible(false);
    setOpenEdit(false);
    setCheckOpenModal(false);
  }

  const handleSaveChanges = async () => {
    try {
      let adjustedDate = date;
      if (moment(timeFrom).format('HH:mm:ss') === moment(timeTo).format('HH:mm:ss')) {
        enqueueSnackbar('Start time and end time cannot be equal', { variant: 'error' });
        return
      }
      if (moment(timeFrom).format('HH:mm:ss') > moment(timeTo).format('HH:mm:ss')) {
        adjustedDate = moment(date).add(1, 'days').toDate();
      }
      if (!selectedTask.length) {
        enqueueSnackbar('Please select Task', { variant: 'error' });
        return
      }
      const response = await axios.put(`time-reports/updateReports/${selectedDateId}`, {
        date: date,
        startTime: moment(date).format('YYYY-MM-DD') + ' ' + moment(timeFrom).format('HH:mm:ss'),
        endTime: moment(adjustedDate).format('YYYY-MM-DD') + ' ' + moment(timeTo).format('HH:mm:ss'),
        categoryId: Number(selectedCategoryId),
        taskId: selectedTask.map((task: any) => task.value),
        description: editedDescription,
      });
      enqueueSnackbar(response.data.message, { variant: 'success' });
      updateReports();
      handleClose();
    } catch (error: any) {
      console.error('Error updating report:', error.message);
      enqueueSnackbar('Error updating report', { variant: 'error' });
    }
  };

  const handleClickCategoryBox = (event: React.MouseEvent) => {
    setCategoryListVisible(!isCategoryListVisible);
    event.stopPropagation();
  };

  const handleCategoryListClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleDateChange = (date: Date) => {
    setDate(date)
  };

  const handleTimeFromChange = (time: Date) => {
    setTimeFrom(time);
  };

  const handleTimeToChange = (time: Date) => {
    setTimeTo(time);
  };

  const handleNodeSelect = (nodeId: number) => {
    // setSelectedCategoryId([nodeId])
    if (nodeId === -1) {
      setCategoryName(['none'])
      setCategoryListVisible(false);
    }
    const selectedCategory = allCategory?.find(node => node.id === Number(nodeId));
    if (selectedCategory) {
      const hasChildren = allCategory.some(node => node.parentId === Number(nodeId));
      if (hasChildren) {
        return true;
      }
      setCategoryName(selectedCategory.name)
      handleTimeFromChange(timeFrom)
      setCategoryListVisible(false);
      setSelectedCategoryId([nodeId])
    } else {
      console.log('Category not found');
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        const { data: categoryData } = await axios.get('category');
        setAllCategory(categoryData);
        if (selectedDateId) {
          const { data: reportID } = await axios.get(`time-reports/getreport/${selectedDateId}`);
          if (reportID) {
            setReportId(reportID);
          }
          setSelectedCategoryId([reportID.categoryId]);
          const selectedCategory = categoryData.find((node: { id: number }) => node.id === reportID.categoryId);
          if (selectedCategory) {
            setCategoryName(selectedCategory.name);
          }
        }
        const hierarchyData = transformToHierarchyCallback(categoryData);
        setCategory(hierarchyData);
      } catch (error: any) {
        console.error('Error fetching categories:', error.message);
      }
    })();
  }, [selectedDateId, transformToHierarchyCallback, location]);

  React.useEffect(() => {
    if (selectedDescription) {
      setEditedDescription(selectedDescription)
    }
    if (selectedDate) {
      setDate(new Date(selectedDate))
    }
    if (selectedDateFrom) {
      setTimeFrom(new Date(selectedDateFrom))
    }
    if (selectedDateTo) {
      setTimeTo(new Date(selectedDateTo))
    }
  }, [selectedDescription, selectedDate, selectedDateFrom, selectedDateTo])

  return (
    <Box sx={{
      width: 400,
      height: '300%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[5px] mx-[25px] text-[var(--text-color)]'>
          <h1 className='text-[20px] text-right font-bold px-[20px] mb-[20px]'>{t('EditReports')}</h1>
          <h2 className='mb-2'>{t('EditDate')}:</h2>
          <div className='flex gap-[5px]'>
            <div className='w-[100%]'>
              <DatePicker
                selected={date}
                onChange={(date: any) => handleDateChange(date)}
                className="timesheets-input-all text-right text-[18px] p-[5px] w-[100%] rounded-[5px]"
                dateFormat="dd/MM/yyyy"
                locale="en-GB"
                popperPlacement="bottom-start" />
            </div>
            <div className='flex gap-[5px] w-[100%]'>
              <DatePicker
                selected={timeFrom}
                onChange={(time: any) => handleTimeFromChange(time)}
                className="timesheets-input-all text-right text-[18px] w-full p-[5px] rounded-[5px]"
                placeholderText="Select Time"
                showTimeSelect
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                showTimeSelectOnly
                timeIntervals={15} />
              <DatePicker
                selected={timeTo}
                onChange={(time: any) => handleTimeToChange(time)}
                className="timesheets-input-all text-right text-[18px] w-full p-[5px] rounded-[5px]"
                showTimeSelect
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                showTimeSelectOnly
                timeIntervals={15} />
            </div>
          </div>
          <h3 className='my-2'>{t('EditCategory')}:</h3>
          <div className="timesheets-category-box bg-[var(--bg-input)] text-[var(--text-color)] relative w-full text-[18px] rounded-[5px] py-[5px] px-[10px] flex justify-between items-center cursor-text"
            onClick={handleClickCategoryBox}>
            {categoryName && <div className="timesheets-category-context width-fix">{categoryName}</div>}
            <div className="timesheets-category-arrow">
              <FontAwesomeIcon icon={faSortDown}
                className='cursor-pointer opacity-50 translate-y-[-2px]' /></div>
            {isCategoryListVisible && (
              <div className="timesheets-category-list fix-height w-full absolute top-[45px] right-0 z-[999] pt-[5px]"
                onClick={handleCategoryListClick}>
                <TreeViewComponent
                  nodes={category}
                  onNodeSelect={handleNodeSelect}
                  updateTree={''}
                  openCheckModal={openCheckModal}
                  selectedCategoryId={''} />
              </div>
            )}
          </div>
          <div className='flex flex-col mb-4'>
            <div className='flex flex-row justify-between'>
              <div className='my-[10px]'>{t('SelectTasks')}:</div>
            </div>
            <TaskEdit
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              category={category}
              reportID={reportID}
              selectedCategoryId={selectedCategoryId} />
          </div>
          <div>
            <h3 className='my-2'>{t('EditDescription')}:</h3>
            <input
              className='w-full bg-[var(--bg-input)] p-[5px] rounded-[5px] edit-reports-input'
              type="text"
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)} />
          </div>
          <div className='flex justify-between items-center gap-4 mt-[30px]'>
            <Button
              variant="contained"
              sx={{
                padding: '5px 15px',
                fontSize: 14,
                color: 'white !important',
                width: '100%'
              }}
              onClick={() => handleSaveChanges()}>
              {t('Change')}
            </Button>
            <Button
              variant="contained"
              sx={{
                padding: '5px 15px',
                fontSize: 14,
                color: 'white !important',
                width: '100%'
              }}
              onClick={() => handleClose()}>
              {t('Close')}
            </Button>
          </div>
        </div>
      </List>
    </Box>
  )
}