import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import DeleteModalAll from '../modal/DeleteModalAll';
import AddModalAll from '../modal/AddModalAll';
import EditModalAll from '../modal/EditModalAll';

interface DepartmentProps {
  cont: any,
  refrefhContCost: any,
  setCheckOpenModal: any,
}

const ContractTypeCostumers: React.FC<DepartmentProps> = ({ cont, refrefhContCost, setCheckOpenModal }) => {
  const [openAddCont, setOpenAddCont] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedDateId, setSelectedDateId] = useState<number | null>(null);
  const [selectedDepId, setSelectedDepId] = useState<number | null>(null);
  const [taskBlocks, setTaskBlocks] = useState<JSX.Element[]>([]);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const sortCont = (cont: any[]) => {
    return cont.slice().sort((a, b) => {
      if (a.active !== b.active) {
        return a.active ? -1 : 1; 
      }
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (nameA > nameB) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleOpen = (dateId: number) => {
    setSelectedDateId(dateId);
    setOpen(true);
  };

  const handleOpenEdit = (dateId: number) => {
    setSelectedDepId(dateId);
    setOpenEdit(true);
    setCheckOpenModal(true);
  };

  const handleOpenAdd = () => {
    setOpenAddCont(true);
    setCheckOpenModal(true);
  };

  const handleChangeSortDirection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSortDirection(event.target.value as 'asc' | 'desc');
  };

  useEffect(() => {
    const fetchTaskBlocks = async () => {
      const sortedTasks = sortCont(cont);
      const blocks: JSX.Element[] = [];
      for (const cont of sortedTasks) {
        const block = (
          <div
            key={cont.id}
            className={`py-[5px] px-[10px] text-[14px] items-center rounded-[10px] ${(!cont.active && cont.active !== null) ? 'bg-[var(--bg-header-fix-menu-false)]' : 'bg-[var(--bg-header-fix-menu)]'} borger-edit mb-[2px] hover:bg-[var(--none-button)]`}>
            <div className="flex justify-between gap-[10px] items-center">
              <div
                className='flex gap-3 items-center cursor-pointer'
                onClick={() => handleOpenEdit(cont.id)}>
                <div
                className={`${(!cont.active && cont.active !== null) ? 'opacity-40' : ''}`}>{cont.name}</div>
              </div>
              <div className='flex gap-3 items-center'>
                <div className='flex justify-end items-center gap-2'>
                  <FontAwesomeIcon icon={faPenToSquare} className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                    onClick={() => handleOpenEdit(cont.id)} />
                  <FontAwesomeIcon icon={faTrashCan} className='text-[16px] cursor-pointer opacity-50 text-[var(--text-color)] hover:opacity-70'
                    onClick={() => handleOpen(cont.id)} />
                </div>
              </div>
            </div>
          </div>
        );
        blocks.push(block);
      }
      setTaskBlocks(blocks);
    };
    fetchTaskBlocks();
  }, [cont, sortDirection]);

  return (
    <>
      <div className='flex justify-between items-center mb-[10px]'>
        <button
          className='border-none text-[16px] rounded-[5px] py-[5px] px-[20px] cursor-pointer bg-[#1976d2] text-white hover:bg-[#176bbe]'
          onClick={() => handleOpenAdd()}>
          {`${t('Create')} ${t('Contract')}`}</button>
        <div className='flex items-center gap-[10px]'>
          <p className='text-[14px] font-semibold'>{t('SortBy')}:</p>
          <select
            className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px]'
            value={sortDirection}
            onChange={handleChangeSortDirection}>
            <option value="asc">from A-Z</option>
            <option value="desc">from Z-A</option>
          </select>
        </div>
      </div>
      <div>
        {taskBlocks}
      </div>
      {openAddCont &&
        <AddModalAll
          openAdd={openAddCont}
          setOpenAdd={setOpenAddCont}
          refresh={refrefhContCost}
          setCheckOpenModal={setCheckOpenModal}
          typeName={'Cont'}
          typeBack={'contractType/add'}
          fieldName={'name'}
          fullName={'Contract'} />}
      {open && <DeleteModalAll
        open={open}
        setOpen={setOpen}
        refresh={refrefhContCost}
        selectedDateId={selectedDateId}
        typeName={'Cont'}
        typeBack={'contractType'}
      />}
      {openEdit && <EditModalAll
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        refresh={refrefhContCost}
        selectedId={selectedDepId}
        setCheckOpenModal={setCheckOpenModal}
        typeName={'Contract'}
        typeBack={'contractType'}
        typeBackAll={'contractType'}
        fieldName={'name'}
      />}
    </>
  )
}

export default ContractTypeCostumers;
