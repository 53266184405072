import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface DeleteModalProps {
  open: boolean,
  setOpen: any,
  selectedUser: any,
  refreshUsers: any
}


const DeleteModalUser: React.FC<DeleteModalProps> = ({ open, setOpen, selectedUser, refreshUsers }) => {
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleDeleteUser = async () => {
    if (selectedUser) {
      try {
        const response = await axios.post(`deleteUser/${selectedUser.id}`);
        if (response.status === 201) {
          refreshUsers();
          setOpen(false);
          enqueueSnackbar(response.data.message, { variant: 'warning', autoHideDuration: 6000 });
          return
        }
        if (response.status === 200) {
          refreshUsers();
          setOpen(false);
          enqueueSnackbar(response.data.message, { variant: 'success' });
          return
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      } catch (error: any) {
        enqueueSnackbar('Error delete user', { variant: 'error' });
      }
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              {t('AreYouUser')}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className='flex justify-between items-center gap-4'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleDeleteUser()}>
                {t('Yes')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleClose()}>
                {t('Cancel')}
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default DeleteModalUser;