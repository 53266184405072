import axios from "axios";
import { SyntheticEvent, useState } from "react"

export const Forgot = () => {
  const [email, setEmail] = useState('');
  const [notify, setNotify] = useState({
    show: false,
    error: false,
    message: ''
  });

  const submit = async (e:SyntheticEvent) => {
    e.preventDefault();
    try {
      const userExists = await existingUser(email);
      if (!userExists) {
        setNotify({
          show: true,
          error: true,
          message: 'Email not found in the database!'
        });
        return;
      }
      await axios.post('forgot', {email});
      setNotify({
        show: true,
        error: false,
        message: 'Please check your email!'
      });
    } catch (e) {
      setNotify({
        show: true,
        error: true,
        message: 'Error occurred!'
      });
    }
  }

  const existingUser = async (email: string) => {
    try {
      const response = await axios.get(`existingUser/${email}`);
      return response.data.exists;
    } catch (error) {
      console.error('Error checking user existence:', error);
      return false;
    }
  };

  let info;

  if (notify.show) {
    info = <div 
    className={`relative px-5 py-3 mb-4 text-center rounded 
    ${notify.error ? ' text-red-900 bg-red-200 border-gray-200' 
    : ' text-grey-900 bg-green-700 border-gray-200'}`}>{notify.message}</div>
  }

  return (
  <div className="max-w-[480px] m-auto flex flex-col justify-start p-[40px] rounded-[5px] form-box">
    {info}
      <h1 className='mt-[20px] text-[26px]'>Please put your email</h1>
      <form className="flex flex-col justify-evenly flex-grow pb-4" onSubmit={submit}>
        <input placeholder='Email' type="text" id="email" onChange={e => setEmail(e.target.value)} />
        <button className="bg-[#1976d2] text-white cursor-pointer rounded-[5px] button-border p-[10px] mx-auto mt-[20px] w-[70%] hover:bg-[#176bbe]" type='submit'>Submit</button>
      </form>
    </div>
)}