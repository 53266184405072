import React, { useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import DatePicker, { registerLocale } from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import TreeViewComponent from '../util/TreeViewComponent';
import { transformToHierarchy } from '../util/tree';
import { useSnackbar } from 'notistack';
import { useLocation } from "react-router-dom";
import TaskEdit from '../timesheetsComp/TaskEdit';
import { t } from 'i18next';
import dayjs from 'dayjs';
import moment from 'moment';
import 'moment-timezone';
import enGB from 'date-fns/locale/en-GB';
import Button from '@mui/material/Button';

registerLocale('en-GB', enGB);

interface EditModalProps {
  setOpenEditTemp: any;
  teamIdTemp: any;
  openCheckModal: any;
  is24HourFormat: any;
  refresh: any;
}

export default function EditTemplate({
  setOpenEditTemp,
  teamIdTemp,
  openCheckModal,
  is24HourFormat,
  refresh }: EditModalProps) {
  const [category, setCategory] = useState<any[]>([]);
  const [reportID, setReportId] = useState<any[]>([]);
  const [allCategory, setAllCategory] = useState<any[]>([]);
  const [categoryName, setCategoryName] = useState<any[]>([]);
  const [isCategoryListVisible, setCategoryListVisible] = useState(false);
  const [editedDescription, setEditedDescription] = useState<string>('');
  const [timeFrom, setTimeFrom] = useState(new Date());
  const [timeTo, setTimeTo] = useState(new Date());
  const [selectedTask, setSelectedTask] = useState<any[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number[]>([]);
  const transformToHierarchyCallback = useCallback(transformToHierarchy, []);
  const [selectedDay, setSelectedDay] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const handleClose = () => {
    setCategoryListVisible(false);
    setOpenEditTemp(false);
  }

  const handleDateChange = (e: any) => {
    const newSelectedDay = e.target.value;
    setSelectedDay(newSelectedDay);
  };

  const formatTime = (date: Date) => {
    return date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  };

  const handleSaveChanges = async () => {
    if ((moment(timeFrom).format('HH:mm:ss')) >= (moment(timeTo).format('HH:mm:ss'))) {
      enqueueSnackbar('Invalid time range: TimeFrom must be less than TimeTo', { variant: 'error' });
      return;
    }
    if (selectedTask.length === 0) {
      enqueueSnackbar('Tasks can`t be empty', { variant: 'error' });
      return
    }
    try {
      await axios.put(`template/${teamIdTemp}`, {
        day_number: Number(selectedDay),
        startTime: formatTime(timeFrom),
        endTime: formatTime(timeTo),
        categoryId: Number(selectedCategoryId),
        taskId: selectedTask ? selectedTask.map((task: any) => task.value).join(',') : '',
        description: editedDescription
      })
      refresh();
      handleClose();
    } catch (error: any) {
      console.error('Error updating report:', error.message);
      enqueueSnackbar('Error updating report', { variant: 'error' });
    }
  };

  const handleClickCategoryBox = (event: React.MouseEvent) => {
    setCategoryListVisible(!isCategoryListVisible);
    event.stopPropagation();
  };

  const handleCategoryListClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const handleTimeFromChange = (time: Date) => {
    setTimeFrom(time);
  };

  const handleTimeToChange = (time: Date) => {
    setTimeTo(time);
  };

  const handleNodeSelect = (nodeId: number) => {
    if (nodeId === -1) {
      setCategoryName(['none'])
      setCategoryListVisible(false);
    }
    const selectedCategory = allCategory?.find(node => node.id === Number(nodeId));
    if (selectedCategory) {
      const hasChildren = allCategory.some(node => node.parentId === Number(nodeId));
      if (hasChildren) {
        return true;
      }
      setCategoryName(selectedCategory.name)
      handleTimeFromChange(timeFrom)
      setCategoryListVisible(false);
      setSelectedCategoryId([nodeId])
    } else {
      console.log('Category not found');
    }
  };

  const convertTimeToDate = (timeString: string): Date => {
    return dayjs(timeString, 'HH:mm').toDate();
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: categoryData } = await axios.get('category');
        setAllCategory(categoryData);
        if (setOpenEditTemp) {
          const { data: report } = await axios.get(`template/temp/${teamIdTemp}`);
          if (report) {
            setReportId(report);
            setSelectedDay(report.day_number);
            setEditedDescription(report.description);
            setTimeFrom(convertTimeToDate(report.startTime));
            setTimeTo(convertTimeToDate(report.endTime));
          }
          setSelectedCategoryId([report.categoryId]);
          const selectedCategory = categoryData.find((node: { id: number }) => node.id === report.categoryId);
          if (selectedCategory) {
            setCategoryName(selectedCategory.name);
          }
        }
        const hierarchyData = transformToHierarchyCallback(categoryData);
        setCategory(hierarchyData);
      } catch (error: any) {
        console.error('Error fetching categories:', error.message);
      }
    })();
  }, [transformToHierarchyCallback, location, setOpenEditTemp, teamIdTemp]);

  return (
    <Box sx={{
      width: 400,
      height: '300%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[5px] mx-[25px] text-[var(--text-color)]'>
          <h1 className='text-[20px] text-right font-bold px-[20px] mb-[20px]'>{t('EditTemp')}</h1>
          <h2 className='mb-2'>{t('EditDate')}:</h2>
          <div className='flex gap-[6px] w-full'>
            <div className='w-[100%]'>
              <select
                onChange={(e) => handleDateChange(e)}
                value={selectedDay || '1'}
                className='admin-sort-user-select bg-[var(--bg-input)] text-[var(--text-color)] p-[5px] rounded-[5px] w-full'>
                <option value="1">{t('Monday')}</option>
                <option value="2">{t('Tuesday')}</option>
                <option value="3">{t('Wednesday')}</option>
                <option value="4">{t('Thursday')}</option>
                <option value="5">{t('Friday')}</option>
                <option value="6">{t('Saturday')}</option>
                <option value="7">{t('Sunday')}</option>
              </select>
            </div>
            <div className='flex gap-[5px] w-[100%]'>
              <DatePicker
                selected={timeFrom}
                onChange={(time: any) => handleTimeFromChange(time)}
                className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                placeholderText="Select Time"
                showTimeSelect
                dateFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                timeFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                showTimeSelectOnly
                timeIntervals={15} />
              <DatePicker
                selected={timeTo}
                onChange={(time: any) => handleTimeToChange(time)}
                className="timesheets-input-all text-right text-[16px] w-full p-[5px] rounded-[5px]"
                showTimeSelect
                dateFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                timeFormat={is24HourFormat ? "HH:mm" : "hh:mm a"}
                showTimeSelectOnly
                timeIntervals={15} />
            </div>
          </div>
          <h3 className='my-2'>{t('EditCategory')}:</h3>
          <div className="timesheets-category-box bg-[var(--bg-input)] text-[var(--text-color)] relative w-full text-[18px] rounded-[5px] py-[5px] px-[10px] flex justify-between items-center cursor-text"
            onClick={handleClickCategoryBox}>
            {categoryName && <div className="timesheets-category-context width-fix">{categoryName}</div>}
            <div className="timesheets-category-arrow">
              <FontAwesomeIcon icon={faSortDown}
                className='cursor-pointer opacity-50 translate-y-[-2px]' /></div>
            {isCategoryListVisible && (
              <div className="timesheets-category-list fix-height w-full absolute top-[45px] right-0 z-[999] pt-[5px]"
                onClick={handleCategoryListClick}>
                <TreeViewComponent
                  nodes={category}
                  onNodeSelect={handleNodeSelect}
                  updateTree={''}
                  openCheckModal={openCheckModal}
                  selectedCategoryId={''} />
              </div>
            )}
          </div>
          <div className='flex flex-col mb-4'>
            <div className='flex flex-row justify-between'>
              <div className='my-[10px]'>{t('SelectTasks')}:</div>
            </div>
            <TaskEdit
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              category={category}
              reportID={reportID}
              selectedCategoryId={selectedCategoryId} />
          </div>
          <div>
            <h3 className='my-2'>{t('EditDescription')}:</h3>
            <input
              className='w-full bg-[var(--bg-input)] p-[5px] pl-[8px] rounded-[5px] edit-reports-input'
              type="text"
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)} />
          </div>
          <div className='flex justify-between items-center gap-4 mt-[25px]'>
            <Button
              variant="contained"
              sx={{
                padding: '5px 20px',
                fontSize: 14,
                color: 'white !important',
                width: '100%'
              }}
              onClick={() => handleSaveChanges()}>
              {t('Change')}
            </Button>
            <Button
              variant="contained"
              sx={{
                padding: '5px 20px',
                fontSize: 14,
                color: 'white !important',
                width: '100%'
              }}
              onClick={() => handleClose()}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
      </List>
    </Box>
  )
}