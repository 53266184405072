import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 410,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface ShowProps {
  openShow: boolean,
  setOpenShow: any,
  errorNameRow: any,
  errorMessage: any,
  dublicateRow: any,
  dublicateWhisRow: any,
  importCSV: any
}

const ShowNon: React.FC<ShowProps> = ({
  openShow,
  setOpenShow,
  errorNameRow,
  errorMessage,
  dublicateRow,
  dublicateWhisRow,
  importCSV }) => {
  const handleCloseShow = () => setOpenShow(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeId = async () => {
    try {
      enqueueSnackbar('change successfully', { variant: 'success' });
      handleCloseShow();
      importCSV(2, 1);
    } catch (error: any) {
      console.error('Error', error);
      enqueueSnackbar('Error', { variant: 'error' });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openShow}
        onClose={handleCloseShow}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openShow}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              {dublicateRow && dublicateRow.length > 0 &&
                <>
                  You have <span
                    className='text-blue-300 underline'>{dublicateRow.length}
                  </span> id dublicate:
                  <div className='flex gap-x-[10px] justify-start items-start text-[var(--text-alert)] ml-[10px] flex-wrap overflow-y-auto'
                    style={{ maxHeight: '160px' }}>
                    {dublicateRow?.map((item: any, index: number) => (
                      <div key={index}>'{item}'</div>
                    ))}
                  </div>
                </>}
              {dublicateWhisRow && dublicateWhisRow.length > 0 &&
                <>
                  You have <span
                    className='text-blue-300 underline'>{dublicateWhisRow.length}
                  </span> {errorMessage}:
                  <div className='flex gap-x-[10px] justify-start items-start text-[var(--text-alert)] ml-[10px] flex-wrap overflow-y-auto'
                    style={{ maxHeight: '160px' }}>
                    {dublicateWhisRow?.map((item: any, index: number) => (
                      <div key={index}>'{item}'</div>
                    ))}
                  </div>
                </>}
              {errorNameRow && errorNameRow.length > 0 &&
                <div>
                  <span
                    className='text-blue-300 underline'>{errorNameRow.length}
                  </span> name does not exist
                </div>}
              {errorMessage === 'there are dublicate id with database' ? 'Do you want update?' : 'Please correct these errors'}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className={`flex w-full ${errorMessage === 'there are dublicate id with database' ? 'gap-[10px] justify-between' : 'justify-center'}`}>
              {errorMessage === 'there are dublicate id with database' ?
                (
                  <Button
                    variant="contained"
                    sx={{
                      padding: '5px 10px',
                      fontSize: 14,
                      width: '100%',
                      color: 'white !important'
                    }}
                    onClick={() => handleChangeId()}>
                    {t('Change')}
                  </Button>
                ) :
                ('')}
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleCloseShow()}>
                {`${errorMessage === 'there are dublicate id with database' ? t('Cancel') : t('Ok')}`}
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ShowNon;