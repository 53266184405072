import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import Button from '@mui/material/Button';
import moment from 'moment';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface AddModalProps {
  openAdd: boolean,
  setOpenAdd: any,
  week: any,
  updateReports: any,
  selectedTemp: any,
  setSelectedIndex: any
}


const AddTempToTimetheets: React.FC<AddModalProps> = ({
  openAdd,
  setOpenAdd,
  week,
  updateReports,
  selectedTemp,
  setSelectedIndex }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpenAdd(false);
  };

  const handleAddTemp = async () => {
    try {
      const templateData = {
        firstDayWeek: moment(week[0].toDate()).startOf('isoWeek').format('YYYY-MM-DD'),
        temp_group_number: Number(selectedTemp)
      }
      if (templateData) {
        await axios.post('time-reports/template', templateData);
        updateReports();
        handleClose();
        setSelectedIndex(-1);
        enqueueSnackbar(`Add success`, { variant: 'success' });
      }
    } catch (error: any) {
      console.error('Error', error);
      enqueueSnackbar('Error', { variant: 'error' });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openAdd}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAdd}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              <div className='mb-[10px] text-[18px]'>{`${t('InsertTemp')}?`}</div>
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className='flex justify-between items-center gap-4'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 20px',
                  fontSize: 14,
                  color: 'white !important',
                  width: '100%'
                }}
                onClick={() => handleAddTemp()}>
                {t('Add')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 20px',
                  fontSize: 14,
                  color: 'white !important',
                  width: '100%'
                }}
                onClick={() => handleClose()}>
                {t('Cancel')}
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default AddTempToTimetheets;