import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { t } from 'i18next';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--bg-form)',
  border: 'var(--border-input)',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4,
};

interface DeleteProps {
  open: boolean,
  setOpen: any,
  refresh: any,
  selectedDateId: any,
  typeName: any,
  typeBack: any,
  fieldName: 'name' | 'title',
  selectChange: string,
  nameItem: string
}

const DeleteSample: React.FC<DeleteProps> = ({
  open,
  setOpen,
  refresh,
  selectedDateId,
  typeName,
  typeBack,
  fieldName,
  selectChange,
  nameItem }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState(false);
  const [row, setRow] = useState<any | null>(null);
  const [rowSelect, setRowSelect] = useState<any | null>(null);
  const [rows, setRows] = useState([]);
  const [select, setSelect] = useState<any | null>(null);
  const handleClose = () => setOpen(false);

  const handleSelectChange = (event: any) => {
    setSelect(Number(event.target.value));
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: dataItem } = await axios.get(`/${typeBack}`);
        const { data: dataItemSelect } = await axios.get(`/${typeBack}/${selectedDateId}`);
        setRow(dataItem);
        setRowSelect(dataItemSelect.id);
      } catch (e) {
        console.error('Error fetching data', e);
      }
    })();
  }, [rows]);

  const handleConfirmClick = () => {
    if (!error) {
      handleDeleteAndClose();
    } else {
      handleChangeDeleteAndClose();
    }
  };

  const handleDeleteAndClose = async () => {
    try {
      await axios.delete(`/${typeBack}/${selectedDateId}`);
      enqueueSnackbar('Delete successfully', { variant: 'success' });
      refresh();
      handleClose();
    } catch (error: any) {
      if (error.response?.status === 403) {
        enqueueSnackbar('Unable to delete, already in use', { variant: 'error' });
        setError(true);
        if (error.response?.data.equipment) {
          const equipmentIds = error.response?.data.equipment.map((item: any) => item.id);
          setRows(equipmentIds);
        }
      } else {
        enqueueSnackbar(`Error delete`, { variant: 'error' });
      }
    }
  };

  const handleChangeDeleteAndClose = async () => {
    if (!select) {
      enqueueSnackbar(`Please choose ${nameItem}`, { variant: 'error' });
      return
    }
    const dataToSend = {
      equipmentId: rows,
      data: {
        [selectChange]: select,
      },
    };
    try {
      await axios.put('equipment/update/many', dataToSend);
      enqueueSnackbar('Successfully updated and delete', { variant: 'success' });
      handleDeleteAndClose();
    } catch (error: any) {
      enqueueSnackbar('Error updating', { variant: 'error' });
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {!error ? <Typography id="transition-modal-title" variant="h6" component="h2"
              className='text-center'>
              {`${t('AreYouSure')} ${t(typeName)}?`}
            </Typography> :
              <Typography id="transition-modal-title" variant="h6" component="h2"
                className='text-center'>
                {`This ${nameItem} is allready used`}
                <p className='text-[11px]'>To delete this {nameItem}, it needs to be replaced with another one</p>
                <div className='flex flex-col justify-start my-[10px]'>
                  <label className='text-left text-[12px] ml-[5px]'>Please choose another {nameItem}</label>
                  <select
                    className="edit-select p-[5px] rounded-[5px] edit-input w-full"
                    value={select || 'none'}
                    onChange={handleSelectChange}>
                    <option value="none">none</option>
                    {row
                      ?.filter((item: any) => item.id !== rowSelect)
                      .filter((item: any) => item.active)
                      .map((item: any) => (
                        <option key={item.id} value={item.id}>
                          {item[fieldName]}
                        </option>
                      ))}
                  </select>
                </div>
              </Typography>}
            <Typography id="transition-modal-description" sx={{ mt: 2 }}
              className='flex justify-between items-center gap-4'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={handleConfirmClick}>
                {t(`${error ? 'Change' : 'Yes'}`)}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleClose()}>
                {t('Cancel')}
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default DeleteSample;