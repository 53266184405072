import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import Button from '@mui/material/Button';


interface TemporaryDrawerProps {
  selectedRole: any;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  refrefhRole: any,
  refreshUserHeder: any,
  refreshRoleAccept: any;
}

export default function RoleEdit({
  selectedRole,
  setShow,
  refrefhRole,
  refreshUserHeder,
  refreshRoleAccept }: TemporaryDrawerProps) {
  const [timesheets, setTimesheets] = useState<any | null>(null);
  const [reports, setReports] = useState<any | null>(null);
  const [reportsEdit, setReportsEdit] = useState<any | null>(null);
  const [approve, setApprove] = useState<any | null>(null);
  const [approveEdit, setApproveEdit] = useState<any | null>(null);
  const [clients, setClients] = useState<any | null>(null);
  const [equipment, setEquipment] = useState<any | null>(null);
  const [booking, setBooking] = useState<any | null>(null);
  const [expenses, setExpenses] = useState<any | null>(null);
  const [orders, setOrders] = useState<any | null>(null);
  const [settings, setSettings] = useState<any | null>(null);
  const [toil, setToil] = useState<any | null>(null);
  const [toilEdit, setToilEdit] = useState<any | null>(null);
  const [users, setUsers] = useState<any | null>(null);
  const [usersEdit, setUsersEdit] = useState<any | null>(null);
  const [colorRole, setColorRole] = useState('#808080');
  const [nameRole, setNameRole] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async () => {
      if (selectedRole) {
        try {
          setNameRole(selectedRole.title)
          setTimesheets(selectedRole.timesheets_get);
          setReports(selectedRole.reports_get);
          setReportsEdit(selectedRole.reports_do);
          setApprove(selectedRole.approve_get);
          setApproveEdit(selectedRole.approve_do);
          setClients(selectedRole.clients_get);
          setEquipment(selectedRole.equipment_get);
          setBooking(selectedRole.booking_get);
          setExpenses(selectedRole.expenses_get);
          setOrders(selectedRole.orders_get);
          setSettings(selectedRole.settings);
          setToil(selectedRole.toil_get);
          setToilEdit(selectedRole.toil_do);
          setUsers(selectedRole.users_get);
          setUsersEdit(selectedRole.users_do);
          if (selectedRole.color) {
            setColorRole(selectedRole.color);
          }

        } catch (e) {
        }
      }
    })();
  }, [selectedRole]);

  const handleRoleChange = async (roleId: number) => {
    try {
      await axios.put(`roles/${roleId}`,
        {
          title: nameRole,
          color: colorRole,
          timesheets_get: Number(timesheets),
          reports_get: Number(reports),
          reports_do: Number(reportsEdit),
          approve_get: Number(approve),
          approve_do: Number(approveEdit),
          clients_get: Number(clients),
          equipment_get: Number(equipment),
          booking_get: Number(booking),
          expenses_get: Number(expenses),
          orders_get: Number(orders),
          settings: Number(settings),
          toil_get: Number(toil),
          toil_do: Number(toilEdit),
          users_get: Number(users),
          users_do: Number(usersEdit)
        });
      refrefhRole();
      refreshRoleAccept();
      refreshUserHeder();
      setShow(false);
      enqueueSnackbar('Role updated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error updating role', { variant: 'error' });
      console.error('Error updating user role:', error);
    }
  };

  return (
    <Box sx={{
      width: 380,
      height: '300%',
      background: 'var(--bg-form)'
    }}
      role="presentation">
      <List>
        <div className='my-[5px] mx-[25px] text-[var(--text-color)]'>
          <h1 className='text-[20px] text-right font-bold mb-[30px]'>{t('EditRole')} {selectedRole.title}</h1>
          <label className='text-[12px] ml-[5px]'>{t('RoleName')}</label>
          <input type="text"
            value={nameRole}
            className="w-full rounded-[5px] edit-input px-[5px]"
            onChange={(e) => setNameRole(e.target.value)}
          />
          <hr className='hr mt-2' />
          <div className='flex mt-[10px] mb-[10px] justify-between items-center'>
            <h5>{t('ColorRole')}:</h5>
            <div className='flex rounded-full w-[40px] h-[40px] overflow-hidden relative'>
              <input type="color"
                value={colorRole}
                className='w-[50px] h-[52px] edit-input absolute right-[-5px] bottom-[-6px] cursor-pointer'
                onChange={(e) => setColorRole(e.target.value)} />
            </div>
          </div >
          <hr className='hr my-[3px]' />
          <h2 className='text-[14px] my-[5px] font-semibold text-right'>{t('RoleAccess')}</h2>
          <div className="flex justify-between items-center px-[5px]">
            <h4>{t('Timesheets')}</h4>
            <div className='flex flex-col'>
              <label className='text-[10px] ml-[5px]'>{t('Availability')}</label>
              <select
                className="edit-select rounded-[5px] edit-input"
                value={timesheets ? timesheets : '0'}
                onChange={(e: any) => setTimesheets(e.target.value)}>
                <option value="0">None</option>
                <option value="1">Self</option>
              </select>
            </div>
          </div>
          <hr className='hr my-[3px]' />
          <div>
            <div className="flex justify-between items-center px-[5px]">
              <h4>{t('Approve')}</h4>
              <div className='flex items-center gap-[5px]'>
                <div className='flex flex-col'>
                  <label className='text-[10px] ml-[5px]'>{t('Availability')}</label>
                  <select
                    className="edit-select rounded-[5px] edit-input"
                    value={approve ? approve : '0'}
                    onChange={(e: any) => setApprove(e.target.value)}>
                    <option value="0">None</option>
                    <option value="1">Team</option>
                    <option value="2">Team + View</option>
                    <option value="3">All</option>
                  </select>
                </div>
                <div className="flex justify-between items-center">
                  <div className='flex flex-col'>
                    <label className='text-[10px] ml-[5px]'>{t('Action')}</label>
                    <select
                      className="edit-select rounded-[5px] edit-input w-[100px]"
                      value={approveEdit ? approveEdit : '1'}
                      onChange={(e: any) => setApproveEdit(e.target.value)}>
                      <option value="1">Self</option>
                      <option value="3">All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <hr className='hr my-[3px]' />
            <div className="flex justify-between items-center px-[5px]">
              <h4>{t('Reports')}</h4>
              <div className='flex items-center gap-[5px]'>
                <div className='flex flex-col'>
                  <label className='text-[10px] ml-[5px]'>{t('Availability')}</label>
                  <select
                    className="edit-select rounded-[5px] edit-input"
                    value={reports ? reports : '0'}
                    onChange={(e: any) => setReports(e.target.value)}>
                    <option value="0">None</option>
                    <option value="1">Self</option>
                    <option value="2">Self + Sub</option>
                    <option value="3">All</option>
                  </select>
                </div>
                <div className="flex justify-between items-center">
                  <div className='flex flex-col'>
                    <label className='text-[10px] ml-[5px]'>{t('Edit')}</label>
                    <select
                      className="edit-select rounded-[5px] edit-input w-[100px]"
                      value={reportsEdit ? reportsEdit : '0'}
                      onChange={(e: any) => setReportsEdit(e.target.value)}>
                      <option value="1">Add</option>
                      <option value="2">Edit</option>
                      <option value="3">All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <hr className='hr my-[3px]' />
            <div className="flex justify-between items-center px-[5px]">
              <h4>{t('Clients')}</h4>
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('Availability')}</label>
                <select
                  className="edit-select rounded-[5px] edit-input"
                  value={clients ? clients : '0'}
                  onChange={(e: any) => setClients(e.target.value)}>
                  <option value="0">None</option>
                  <option value="1">Self</option>
                  <option value="2">Self + Sub</option>
                  <option value="3">All</option>
                </select>
              </div>
            </div>
            <hr className='hr my-[3px]' />
            <div className="flex justify-between items-center px-[5px]">
              <h4>{t('Equipment')}</h4>
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('Availability')}</label>
                <select
                  className="edit-select rounded-[5px] edit-input"
                  value={equipment ? equipment : '0'}
                  onChange={(e: any) => setEquipment(e.target.value)}>
                  <option value="0">None</option>
                  <option value="1">Self</option>
                  <option value="2">Self + Sub</option>
                  <option value="3">All</option>
                </select>
              </div>
            </div>
            <hr className='hr my-[3px]' />
            <div className="flex justify-between items-center px-[5px]">
              <h4>{t('Booking')}</h4>
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('Availability')}</label>
                <select
                  className="edit-select rounded-[5px] edit-input"
                  value={booking ? booking : '0'}
                  onChange={(e: any) => setBooking(e.target.value)}>
                  <option value="0">None</option>
                  <option value="1">Self</option>
                  <option value="2">Self + Sub</option>
                  <option value="3">All</option>
                </select>
              </div>
            </div>
            <hr className='hr my-[3px]' />
            <div className="flex justify-between items-center px-[5px]">
              <h4>{t('Expenses')}</h4>
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('Availability')}</label>
                <select
                  className="edit-select rounded-[5px] edit-input"
                  value={expenses ? expenses : '0'}
                  onChange={(e: any) => setExpenses(e.target.value)}>
                  <option value="0">None</option>
                  <option value="1">Self</option>
                  <option value="2">Self + Sub</option>
                  <option value="3">All</option>
                </select>
              </div>
            </div>
            <hr className='hr my-[3px]' />
            <div className="flex justify-between items-center px-[5px]">
              <h4>{t('Orders')}</h4>
              <div className='flex flex-col'>
                <label className='text-[10px] ml-[5px]'>{t('Availability')}</label>
                <select
                  className="edit-select rounded-[5px] edit-input"
                  value={orders ? orders : '0'}
                  onChange={(e: any) => setOrders(e.target.value)}>
                  <option value="0">None</option>
                  <option value="1">Self</option>
                  <option value="2">Self + Sub</option>
                  <option value="3">All</option>
                </select>
              </div>
            </div>
            <div className="flex justify-between items-center px-[5px]">
              <h4>{t('Settings')}</h4>
              <div className='flex flex-col w-[100px]'>
                <label className='text-[10px] ml-[5px]'>{t('Availability')}</label>
                <select
                  className="edit-select rounded-[5px] edit-input"
                  value={settings ? settings : '0'}
                  onChange={(e: any) => setSettings(e.target.value)}>
                  <option value="0">None</option>
                  <option value="1">See</option>
                </select>
              </div>
            </div>
            <hr className='hr my-[3px]' />
            <div className="flex justify-between items-center px-[5px]">
              <h4>{t('Toil')}</h4>
              <div className='flex items-center gap-[5px]'>
                <div className='flex flex-col'>
                  <label className='text-[10px] ml-[5px]'>{t('Availability')}</label>
                  <select
                    className="edit-select rounded-[5px] edit-input"
                    value={toil ? toil : '0'}
                    onChange={(e: any) => setToil(e.target.value)}>
                    <option value="0">None</option>
                    <option value="1">Self</option>
                    <option value="2">Self + Sub</option>
                    <option value="3">All</option>
                  </select>
                </div>
                <div className="flex justify-between items-center">
                  <div className='flex flex-col'>
                    <label className='text-[10px] ml-[5px]'>{t('Edit')}</label>
                    <select
                      className="edit-select rounded-[5px] edit-input w-[100px]"
                      value={toilEdit ? toilEdit : '0'}
                      onChange={(e: any) => setToilEdit(e.target.value)}>
                      <option value="0">None</option>
                      <option value="1">All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <hr className='hr my-[3px]' />
            <div className="flex justify-between items-center px-[5px]">
              <h4>{t('Users')}</h4>
              <div className='flex items-center gap-[5px]'>
                <div className='flex flex-col'>
                  <label className='text-[10px] ml-[5px]'>{t('Availability')}</label>
                  <select
                    className="edit-select rounded-[5px] edit-input"
                    value={users ? users : '0'}
                    onChange={(e: any) => setUsers(e.target.value)}>
                    <option value="0">None</option>
                    <option value="1">Self</option>
                    <option value="2">Self + Sub</option>
                    <option value="3">All</option>
                  </select>
                </div>
                <div className="flex justify-between items-center">
                  <div className='flex flex-col'>
                    <label className='text-[10px] ml-[5px]'>{t('Edit')}</label>
                    <select
                      className="edit-select rounded-[5px] edit-input"
                      value={usersEdit ? usersEdit : '0'}
                      onChange={(e: any) => setUsersEdit(e.target.value)}>
                      <option value="0">None</option>
                      <option value="1">Add</option>
                      <option value="2">Add + Edit</option>
                      <option value="3">All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <hr className='hr my-[3px]' />
            <div className='flex mt-[20px] justify-center items-center gap-[20px]'>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => handleRoleChange(selectedRole.id)}>
                {t('Change')}
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: '5px 10px',
                  fontSize: 14,
                  width: '100%',
                  color: 'white !important'
                }}
                onClick={() => setShow(false)}>
                {t('Cancel')}
              </Button>
            </div>
          </div>
        </div>
      </List>
    </Box>
  )
}